import Quiz from "../dto/quiz/quiz";
import { useReducer } from "react";
import produce from "immer";

export const quizActions = {
  saveQuiz: "saveQuiz",
  deleteQuiz: "deleteQuiz",
  addQuestion: "addQuestion",
  modifyQuestion: "modifyQuestion",
  deleteQuestion: "deleteQuestion",
  modifyOption: "modifyOption",
  deleteOption: "deleteOption",
  setField: "setField",
  addStudent: "addStudent",
  addStudents: "addStudents",
  deleteStudent: "deleteStudent",
  setQuestions: "setQuestions",
};

export const initialState = new Quiz().getPlainCopy();

function reducer(state, action) {
  switch (action.type) {
    case quizActions.saveQuiz:
      return action.payload;
    case quizActions.deleteQuiz:
      return initialState;
    case quizActions.addQuestion:
      state.questions.push(action.payload);
      return;
    case quizActions.modifyQuestion:
      state.questions[action.payload.questionIndex] = action.payload.question;
      return;
    case quizActions.deleteQuestion:
      state.questions.splice(action.payload.questionIndex, 1);
      return; 
    case quizActions.modifyOption:
      state.questions[action.payload.questionIndex].options[
        action.payload.optionIndex
      ] = action.payload.option;
      return;
    case quizActions.deleteOption:
      state.questions[action.payload.questionIndex].options.splice(
        action.payload.optionIndex,
        1
      );
      return;
    case quizActions.addStudent:
      state.students.push(action.payload);
      return;
    case quizActions.addStudents: {
      const students = action.payload.filter(
        (s) => !state.students.some((st) => st.email === s.email)
      );
      state.students.push(...students);
      return;
    }
    case quizActions.deleteStudent:
      const index = state.students.findIndex((s) => s.id === action.payload);
      if (index > -1) {
        state.students.splice(index, 1);
      }
      return;
    case quizActions.setQuestions: 
      state.questions = action.payload;
      return;
    default:
      throw new Error("quiz reducer error");
  }
}
const curriedReducerFunction = produce(reducer);

export default function useQuizReducer(quiz) {
  const [state, dispatch] = useReducer(
    curriedReducerFunction,
    // reducer,
    quiz ? quiz : initialState
  );

  return {
    quiz: state,
    quizDispatch: dispatch,
  };
}
