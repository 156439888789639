import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import IconButton, {
  btnType,
  iconType,
} from "../BasicComponents/IconButton/IconButton";
import "./ProvidersLogin.scss";
import { NotificationContext } from "../../contexts/NotificationContext";

export const providers = {
  google: "google",
  facebook: "facebook",
};

const ProvidersLogin = ({ signed, upgradeAnonymous = false }) => {
  const { t } = useTranslation();
  const { signInGoogle, signInFacebook } = useContext(AuthContext);
  const { setNotificationError } = useContext(NotificationContext);
  const handelSignInProvider = async (provider) => {
    let response;
    try {
      if (provider === providers.google) {
        response = await signInGoogle(upgradeAnonymous);
      } else {
        response = await signInFacebook(upgradeAnonymous);
      }
      signed && signed(response, provider);
    } catch (e) {
      console.log("error", e);
      setNotificationError(e.message);
    }
  };
  return (
    <div className="providersLogin">
      <IconButton
        onClick={() => handelSignInProvider(providers.google)}
        className="btn-light-border no-shadow"
        type={iconType.googleIcon}
        btnType={btnType.light}
        label={t("login.sign_in_google")}
      />
      <IconButton
        onClick={() => handelSignInProvider(providers.facebook)}
        type={iconType.facebookIcon}
        btnType={btnType.light}
        className="btn-light-border no-shadow"
        label={t("login.sign_in_facebook")}
      />
    </div>
  );
};

export default ProvidersLogin;
