import { useLayoutEffect, useRef } from "react";
import { isElementInViewport } from "../../../utils/domUtils";

const ScrollInToView = ({
  children,
  offset = 0,
  delay = 0,
  offsetViewportBottom = 0,
  ...props
}) => {
  const element = useRef(null);
  useLayoutEffect(() => {
    const scrollTimeout = setTimeout(() => {
      if (isElementInViewport(element.current, offsetViewportBottom)) {
        return;
      }
      if (offset) {
        const y =
          element.current.getBoundingClientRect().top +
          window.pageYOffset +
          offset;
        window.scrollTo({ top: y, behavior: "smooth" });
      } else {
        element.current.scrollIntoView({ behavior: "smooth" });
      }
    }, delay);
    return () => {
      clearTimeout(scrollTimeout);
    };
  }, [delay, offset, offsetViewportBottom]);
  return (
    <div ref={element} {...props}>
      {children}
    </div>
  );
};

export default ScrollInToView;
