import { ErrorMessage, Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { quizTakenActions } from "../../../../../reducers/quizTakenReducer";
import useDynamicContext from "../../../../../Hooks/useDynamicContext";
import { contextKeys } from "../../../../../contexts/DynamicContext";
import CheckBox from "../../../../BasicComponents/CheckBox/CheckBox";
import { removeFromArray } from "../../../../../utils/generic";
import Button from "../../../../BasicComponents/Button/Button";

const QuestionCheckboxComponent = ({ index, question }) => {
  const { saveDispatch, isLast } = useDynamicContext(contextKeys.quizTaken);
  const { t } = useTranslation();
  const id = "question" + (index + 1);
  const values = question?.options?.filter((o) => o.checked).map((o) => o.id);
  return (
    <div className="questionWrapper">
      <label className="question" htmlFor={id}>
        <span>{index + 1}</span>
        {question?.question}
      </label>
      <Formik
        initialValues={{
          options: values || [],
        }}
        onSubmit={(values) => {
          saveDispatch({
            type: quizTakenActions.saveQuestion,
            payload: {
              questionType: quizTakenActions.optionResponse,
              questionIndex: index,
              options: question?.options?.map((o) => {
                return {
                  ...o,
                  checked: values.options.includes(`${o.id}`),
                };
              }),
            },
          });
        }}
      >
        {({ values, errors, handleBlur, setValues }) => {
          return (
            <Form>
              <ul>
                {question?.options?.map((option) => (
                  <li key={option.id}>
                    <CheckBox
                      id={option.id + "_checkbox"}
                      checked={values.options.includes(`${option.id}`)}
                      onChange={(e) => {
                        let tmpOpt = [...values.options];
                        if (e.target.checked) {
                          if (!values.options.includes(`${option.id}`)) {
                            tmpOpt.push(`${option.id}`);
                            setValues({ options: tmpOpt });
                          }
                        } else {
                          tmpOpt = removeFromArray(tmpOpt, `${option.id}`);
                          setValues({ options: tmpOpt });
                        }
                      }}
                      onBlur={handleBlur}
                      label={option.option}
                    />
                  </li>
                ))}
              </ul>
              <div className="questionParagraphbuttons">
                <Button type="submit" className="no-shadow nextButton">
                  {t(isLast ? "quiz.finish" : "quiz.next")}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default QuestionCheckboxComponent;
