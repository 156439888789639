import { createContext } from "react";
import ResizeComponent from "../components/ResizeComponent/ResizeComponent";

const state = {
  questions: {},
};
export const UIElementsContext = createContext(state);

export default function UIElementsProvider({ children }) {
  const setQuizInput = (inputEl, id) => {
    if (id && inputEl?.current) {
      state.questions[id] = inputEl?.current;
    }
  };

  const removeQuizInput = (id) => {
    if (state.questions[id]) {
      delete state.questions[id];
    }
  };

  const getQuizInput = (invalidFieldObj) => {
    if (invalidFieldObj.id && state.questions[invalidFieldObj.id]) {
      if (invalidFieldObj.optionId) {
        return state.questions[invalidFieldObj.optionId];
      }
      return state.questions[invalidFieldObj.id];
    }
  };

  return (
    <UIElementsContext.Provider
      value={{
        setQuizInput,
        removeQuizInput,
        getQuizInput,
      }}
    >
      {children}
      <ResizeComponent />
    </UIElementsContext.Provider>
  );
}
