import { useCallback } from "react";
import { questionTypeEnum } from "../../../dto/enum";
import QuestionParagraphComponent from "./QuestionParagraphComponent/QuestionParagraphComponent";
import QuestionBooleanComponent from "./QuestionBooleanComponent/QuestionBooleanComponent";
import QuestionMultichoiceComponent from "./QuestionMultichoiceComponent/QuestionMultichoiceComponent";
import "./QuestionComponent.scss";

const QuestionComponent = ({ index, question }) => {
  const renderQuestion = useCallback((type) => {
    switch (type) {
      case questionTypeEnum.PARAGRAPH:
        return QuestionParagraphComponent;
      case questionTypeEnum.BOOLEAN:
        return QuestionBooleanComponent;
      case questionTypeEnum.MULTICHOICE:
        return QuestionMultichoiceComponent;
      default:
        return null;
    }
  }, []);

  const QuestionTypeComponent = renderQuestion(question?.questionType);

  if (!QuestionTypeComponent) {
    return null;
  }
  return (
    <div className="questionComponent">
      <QuestionTypeComponent index={index} question={question} />
    </div>
  );
};

export default QuestionComponent;
