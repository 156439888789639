import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { quizTakenActions } from "../../../../../reducers/quizTakenReducer";
import useDynamicContext from "../../../../../Hooks/useDynamicContext";
import { contextKeys } from "../../../../../contexts/DynamicContext";
import Button from "../../../../BasicComponents/Button/Button";
import CheckBox from "../../../../BasicComponents/CheckBox/CheckBox";
import { InputSize } from "../../../../BasicComponents/FocusInput/FocusInput";
import ErrorMessage from "../../../../BasicComponents/ErrorMessage/ErrorMessage";

const QuestionRatioComponent = ({ index, question }) => {
  const { saveDispatch, isLast } = useDynamicContext(contextKeys.quizTaken);
  const { t } = useTranslation();
  const id = "question" + (index + 1);
  const option = question?.options?.find((o) => o.checked)?.id;
  let validate = {};
  if (question?.required) {
    validate = {
      option: Yup.string().required(t("quiz.required")),
    };
  }
  return (
    <div className="questionWrapper">
      <label className="question" htmlFor={id}>
        <span>{index + 1}.</span>
        {question?.question}
      </label>
      <Formik
        initialValues={{
          option: option || "",
        }}
        validationSchema={Yup.object().shape(validate)}
        onSubmit={(values) => {
          saveDispatch({
            type: quizTakenActions.saveQuestion,
            payload: {
              questionType: quizTakenActions.optionResponse,
              questionIndex: index,
              options: question?.options?.map((o) => {
                return {
                  ...o,
                  checked: `${o.id}` === values.option,
                };
              }),
            },
          });
        }}
      >
        {({ values, errors, handleBlur, setValues }) => {
          return (
            <Form>
              <ul>
                {question?.options?.map((option) => (
                  <li key={option.id}>
                    <CheckBox
                      id={option.id + "_radio"}
                      type="radio"
                      checked={option.id == values?.option}
                      onChange={(e) => {
                        setValues({ option: `${option.id}` });
                      }}
                      onBlur={handleBlur}
                      label={option.option}
                    />
                  </li>
                ))}
              </ul>
              <ErrorMessage
                show={!!errors?.option}
                message={errors?.option}
                size={InputSize.lg}
              />
              <div className="questionParagraphbuttons">
                <Button type="submit" className="no-shadow nextButton">
                  {t(isLast ? "quiz.finish" : "quiz.next")}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default QuestionRatioComponent;
