import { createRef, Suspense } from "react";
import { useLoaderData, useLocation, useOutlet } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { usePrevious } from "../../../Hooks/usePrevious";
import LoadingFallback from "../../LoadingComponents/LoadingFallback/LoadingFallback";

import "./PageTransition.scss";

const routesRefs = {};
export const pageAnimations = {
  leftToRight: "leftToRight",
  rightToLeft: "rightToLeft",
  fadeTransition: "fadeTransition",
};
const PageTransition = ({ children }) => {
  const { pathname } = useLocation();
  const prePathname = usePrevious(pathname);
  const currentOutlet = useOutlet();
  const transitionAnimation = useLoaderData();
  let className = pageAnimations.fadeTransition;

  if (
    transitionAnimation &&
    prePathname &&
    prePathname in transitionAnimation
  ) {
    className = transitionAnimation[prePathname];
  }

  if (!routesRefs[pathname]) {
    routesRefs[pathname] = createRef();
  }

  return (
    <TransitionGroup className={`pageTransitionContainer ${className}`}>
      {[
        <CSSTransition
          rid={pathname}
          key={pathname}
          timeout={300}
          classNames="page"
          unmountOnExit
          appear
        >
          {() => (
            <div
              id={pathname}
              ref={routesRefs[pathname]}
              className={`page`}
            >
              <Suspense fallback={<LoadingFallback />}>
                {!!children ? children : currentOutlet}
              </Suspense>
            </div>
          )}
        </CSSTransition>,
      ]}
    </TransitionGroup>
  );
};

export default PageTransition;
