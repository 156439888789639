import {createContext} from "react";
import useStoreReducer from "../reducers/storeReducer";

export const StoreContext = createContext({});
export default function StoreProvider({ children }) {
  const { quizzes, storeDispatch } = useStoreReducer();
  return (
    <StoreContext.Provider
      value={{
        quizzes,
        storeDispatch,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
}
