import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NewQuizContext } from "../../../contexts/NewQuizContext";
import { UIElementsContext } from "../../../contexts/UIElementsContext";
import { questionTypeEnum } from "../../../dto/enum";
import QuestionOption from "../../../dto/quiz/option";
import { quizActions } from "../../../reducers/quizReducer";
import CheckBox from "../../BasicComponents/CheckBox/CheckBox";
import { InputSize } from "../../BasicComponents/FocusInput/FocusInput";
import IconButton, {
  btnType,
  iconType,
} from "../../BasicComponents/IconButton/IconButton";
import Input from "../../BasicComponents/Input/Input";
import ListAnimation from "../../hoc/ListAnimation/ListAnimation";
import ScrollInToView from "../../hoc/ScrollInToView/ScrollInToView";
import OptionEditor from "../OptionEditor/OptionEditor";
import "./QuestionEditor.scss";

const QuestionEditor = ({
  index,
  question,
  conf = { showNumber: false, showPoints: false },
}) => {
  const { quizDispatch } = useContext(NewQuizContext);
  const { setQuizInput, removeQuizInput } = useContext(UIElementsContext);
  const { t } = useTranslation();
  const label = `${t(
    "quiz_editor.question_type." +
      (question?.questionType === questionTypeEnum.BOOLEAN
        ? "boolean"
        : question?.questionType === questionTypeEnum.MULTICHOICE
        ? "options"
        : "short_answer")
  )} ${t("quiz_editor.question_type.question")}`;
  const inputEl = useRef({});

  useEffect(() => {
    setQuizInput(inputEl, question?.id);
    return () => {
      removeQuizInput(question?.id);
    };
  }, []);

  const saveField = (value, field) => {
    const newQuestion = {
      ...question,
      [field]: value,
    };
    quizDispatch({
      type: quizActions.modifyQuestion,
      payload: {
        questionIndex: index,
        question: newQuestion,
      },
    });
  };
  const handleQuestion = (e) => saveField(e.target.value, "question");
  const setPoints = (e) => saveField(e.target.value, "points");
  const setIsCorrect = (e) => saveField(e.target.checked, "isCorrect");
  const addOption = () => {
    const newQuestionOptions = [...question.options];
    newQuestionOptions.push(new QuestionOption().getPlainCopy());
    saveField(newQuestionOptions, "options");
  };
  const deleteQuestion = () => {
    quizDispatch({
      type: quizActions.deleteQuestion,
      payload: {
        questionIndex: index,
      },
    });
  };

  return (
    <ScrollInToView
      className="question-editor"
      delay={300}
      offsetViewportBottom={200}
    >
      {!!conf.showNumber && <span>{index + 1}</span>}
      <div className="question-editor-main">
        <Input
          value={question?.question}
          onChange={handleQuestion}
          size={InputSize.md}
          label={label}
          id={question?.id}
          ref={inputEl}
          contentEditable
          focus
        />
        <IconButton
          onClick={deleteQuestion}
          type={iconType.deleteIcon}
          btnType={btnType.light}
        />
      </div>
      {!!conf.showPoints && (
        <input
          type="number"
          value={question?.points ? question?.points : ""}
          onChange={setPoints}
        />
      )}
      {question?.questionType === questionTypeEnum.BOOLEAN && (
        <>
          <span>{t("quiz_editor.question_type.boolean_label")}</span>
          <CheckBox
            id={question.id}
            checked={question?.isCorrect}
            onChange={setIsCorrect}
            label={t("quiz_editor.question_type.true")}
            labelIncorrect={t("quiz_editor.question_type.false")}
          />
        </>
      )}
      {question?.questionType === questionTypeEnum.MULTICHOICE && (
        <>
          <ListAnimation
            component="ul"
            className="question-editor-options"
            itemElement="li"
          >
            {question?.options.map((option, optionIndex) => (
              <OptionEditor
                key={option.id}
                option={option}
                questionIndex={index}
                optionIndex={optionIndex}
                focus={optionIndex !== 0}
              />
            ))}
          </ListAnimation>
          <IconButton
            onClick={addOption}
            type={iconType.plusIcon}
            className="more-icon"
            btnType={btnType.light}
            label={t("quiz_editor.question_type.more_options")}
          />
        </>
      )}
    </ScrollInToView>
  );
};

export default QuestionEditor;
