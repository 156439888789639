import { NavLink } from "react-router-dom";
import IconButton, { btnType } from "../IconButton/IconButton";
import "./Link.scss";

const Link = ({
  to,
  children,
  iconType,
  className = "",
  isButton = false,
  onClick,
  ...props
}) => {
  const content = (
    <>
      {iconType && (
        <IconButton type={iconType} btnType={btnType.light} elementType="i" />
      )}
      <span>{children}</span>
    </>
  );

  if (isButton) {
    return (
      <button className={`link ${className}`} title={children} onClick={onClick} {...props}>
        {content}
      </button>
    );
  }
  return (
    <NavLink
      to={to}
      title={children}
      className={`link ${className}`}
      {...props}
    >
      {content}
    </NavLink>
  );
};

export default Link;
