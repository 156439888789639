import { createContext, useContext, useEffect, useRef, useState } from "react";
import { initialState } from "../reducers/quizReducer";
import useQuizTakenReducer, {
  quizTakenActions,
} from "../reducers/quizTakenReducer";
import QuizTaken from "../dto/quiz/quizTaken";
import { AuthContext } from "./AuthContext";
import { studentTakingEnum } from "../dto/enum";
import { NotificationContext } from "./NotificationContext";
import { useHasSignedEvt } from "../Hooks/auth/useHasSignedEvt";
import { reassignQuiz } from "../services/quizTakenService";

export const QuizTakenContext = createContext(initialState);

export default function QuizTakenProvider({ quiz: quizBd, children }) {
  const { user } = useContext(AuthContext);
  const { quizTaken, quizDispatch } = useQuizTakenReducer();
  const { setNotificationError } = useContext(NotificationContext);
  const [saveFinishQuiz, setSaveFinishQuiz] = useState(false);
  const isLast = QuizTaken.isLast(quizTaken);
  const isFirst = QuizTaken.isFirst(quizTaken);
  const [loading, setLoading] = useState(false);
  const startQuizOnLogin = useRef(false);
  const setStartQuizOnLogin = () => (startQuizOnLogin.current = true);

  useHasSignedEvt({
    signIn: async () => {
      const quiz = await retrieveQuiz();
      if (
        quiz.studentStatus === studentTakingEnum.NEW &&
        (startQuizOnLogin.current || user.isAnonymous)
      ) {
        startQuiz(quiz);
      }
    },
  });

  // useEffect(() => {
  //   console.log("user", user);
  //   if (!!user && !quizInitialized) {
  //     quizInitialized = true;
  //     (async () => {
  //       const quiz = await retrieveQuiz(); // get quiz from DB or create a new quiz
  //       if (quiz && quiz.studentStatus !== studentTakingEnum.STARTED) {
  //         // start quiz directly if anonymous
  //         await startQuiz();
  //       }
  //     })();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user]);

  useEffect(() => {
    if (isLast && saveFinishQuiz) {
      finishQuiz();
      setSaveFinishQuiz(false);
    } else if (
      quizTaken.currentQuestion > 0 &&
      quizTaken.studentStatus === studentTakingEnum.STARTED
    ) {
      // save to DB
      saveDb(quizTaken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizTaken.currentQuestion]);

  const retrieveQuiz = async () => {
    setLoading(true);
    let quizToStore = null;
    try {
      // get quiz from local storage or DB
      quizToStore = await QuizTaken.getSavedQuiz(quizBd.id, user.id);
      if (!quizToStore) {
        quizToStore = createQuiz();
      }
      quizDispatch({
        type: quizTakenActions.saveQuizTaken,
        payload: quizToStore,
      });
    } catch (e) {
      console.log(e);
      setNotificationError(e.message);
    }
    setLoading(false);
    return quizToStore;
  };
  const createQuiz = () => {
    return QuizTaken.quizTakenFromQuiz(quizBd, user, {
      dateCreated: new Date(),
      studentStatus: studentTakingEnum.NEW,
    });
  };
  const startQuiz = async (quizToStart = quizTaken) => {
    try {
      const dateStarted = new Date();
      let savedQuiz = !quizToStart.id ? createQuiz() : quizToStart;
      savedQuiz = {
        ...savedQuiz,
        dateStarted,
        studentStatus: studentTakingEnum.STARTED,
      };
      const dbQuiz = await saveDb(savedQuiz);
      quizDispatch({
        type: quizTakenActions.saveQuizTaken,
        payload: dbQuiz,
      });
      return true;
    } catch (e) {
      console.log(e.message);
      setNotificationError(e.message);
      return false;
    }
  };
  const questionSaved = (data) => {
    if (isLast) {
      setSaveFinishQuiz(true);
    }
    quizDispatch(data);
  };
  const saveDb = async (quizTk) => {
    const dbQuiz = await QuizTaken.saveQuiz(quizTk);
    QuizTaken.saveOnLocal(dbQuiz);
    return dbQuiz;
  };
  const finishQuiz = async () => {
    // setLoading("QuizTakenContext");
    const finishedQT = QuizTaken.getFinishQuiz(quizTaken);
    const savedQuiz = await saveDb(finishedQT);
    if (savedQuiz) {
      quizDispatch({
        type: quizTakenActions.saveQuizTaken,
        payload: savedQuiz,
      });
    }
    // removeLoading("QuizTakenContext");
  };

  const reassignQuizToUser = async (userId, quizTaken) => {
    // reassignQuiz on Db
    await reassignQuiz(userId, quizTaken.id);
    QuizTaken.deleteFromLocal(quizTaken);
    const quizToSave = QuizTaken.reassignQuizToUser(quizTaken, userId);
    // reassignInLocalStore
    QuizTaken.saveOnLocal(quizToSave);
    // reassignQuiz on store
    quizDispatch({
      type: quizTakenActions.saveQuizTaken,
      payload: quizToSave,
    });
  };

  return (
    <QuizTakenContext.Provider
      value={{
        quizTaken,
        saveDispatch: questionSaved,
        startQuiz,
        finishQuiz,
        isLast,
        isFirst,
        loading,
        setStartQuizOnLogin,
        reassignQuizToUser,
      }}
    >
      {children}
    </QuizTakenContext.Provider>
  );
}
