import { useEffect } from "react";
import { createPortal } from "react-dom";
import { isHidden } from "../../utils/domUtils";
import {
  unsubscribeEvt,
  subscribeEvt,
  triggerEvt,
} from "../../utils/EventRegister";
import "./ResizeComponent.scss";

const sizesList = {
  xxxs: "xxxs",
  xxs: "xxs",
  xs: "xs",
  sm: "sm",
  md: "md",
  lg: "lg",
  elg: "elg",
};
const maxXs = [sizesList.xxxs, sizesList.xxs, sizesList.xs];
const minSm = [sizesList.sm, sizesList.md, sizesList.lg, sizesList.elg];

let sizesWrapper = document.getElementById("sizes-wrapper");
if (!sizesWrapper) {
  sizesWrapper = document.createElement("div");
  sizesWrapper.id = "sizes-wrapper";
  document.getElementsByTagName("body")[0].appendChild(sizesWrapper);
}
let currentSize;
let prevSize;

function resizeListener() {
  for (let size in sizesList) {
    if (!isHidden(document.getElementById(size))) {
      currentSize = size;
      break;
    }
  }
  if (currentSize === prevSize) {
    return;
  }
  triggerEvt(currentSize, prevSize);
  if (maxXs.includes(currentSize) && !maxXs.includes(prevSize)) {
    triggerEvt("maxXs", prevSize);
  }
  if (minSm.includes(currentSize) && !minSm.includes(prevSize)) {
    triggerEvt("minSm", prevSize);
  }
  prevSize = currentSize;
}

export let sizes = {
  xxxs: () => sizesList.xxxs === currentSize,
  xxs: () => sizesList.xxs === currentSize,
  xs: () => sizesList.xs === currentSize,
  sm: () => sizesList.sm === currentSize,
  md: () => sizesList.md === currentSize,
  lg: () => sizesList.lg === currentSize,
  elg: () => sizesList.elg === currentSize,
  maxXs: () => sizes.xxxs() || sizes.xxs() || sizes.xs(),
  minSm: () => sizes.sm() || sizes.md() || sizes.lg() || sizes.elg(),
};
window.ren = sizes;
const fnfSubs = (size) => (cb) => {
  if (sizes[size]()) cb(prevSize);
  subscribeEvt(cb, size);
};
export const onSizes = {
  subsXxxs: fnfSubs("xxxs"),
  subsXxs: fnfSubs("xxs"),
  subsXs: fnfSubs("xs"),
  subsSm: fnfSubs("sm"),
  subsMd: fnfSubs("md"),
  subsLg: fnfSubs("lg"),
  subsElg: fnfSubs("elg"),
  subsMaxXs: fnfSubs("maxXs"),
  subsMinSm: fnfSubs("minSm"),
  unsubsXxxs: (id) => unsubscribeEvt(id, "xxxs"),
  unsubsXxs: (id) => unsubscribeEvt(id, "xxs"),
  unsubsXs: (id) => unsubscribeEvt(id, "xs"),
  unsubsSm: (id) => unsubscribeEvt(id, "sm"),
  unsubsMd: (id) => unsubscribeEvt(id, "md"),
  unsubsLg: (id) => unsubscribeEvt(id, "lg"),
  unsubsElg: (id) => unsubscribeEvt(id, "elg"),
  unsubsMaxXs: (id) => unsubscribeEvt(id, "maxXs"),
  unsubsMinSm: (id) => unsubscribeEvt(id, "minSm"),
};

const ResizeComponent = () => {
  useEffect(() => {
    let doit;
    const resize = () => {
      clearTimeout(doit);
      doit = setTimeout(resizeListener, 100);
    };
    // initial call
    resizeListener();
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);
  return createPortal(
    window.Object.keys(sizesList).map((size) => <div id={size} key={size} />),
    sizesWrapper
  );
};

export default ResizeComponent;
