import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import MenuIconAnimation from "../../AnimationComponents/animations/MenuIconAnimation/MenuIconAnimation";
import "./HeaderMenuMobile.scss";

const HeaderMenuMobile = ({ items }) => {
  const location = useLocation();
  const [closed, setClosed] = useState(true);
  const { t } = useTranslation();
  const menuHeight = useRef(0);

  useEffect(() => {
    if (!closed) {
      setClosed(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <>
      <button
        onClick={() => setClosed(!closed)}
        className="menuButton"
        title={t("header.menu")}
        type="button"
      >
        <MenuIconAnimation active={!closed} />
      </button>
      <menu
        className={`headerMenuMobile ${
          closed ? "headerMenuMobile-closed" : ""
        }`}
        ref={(el) => {
          menuHeight.current = el?.scrollHeight || 0;
        }}
        style={{
          height: closed ? "0px" : `${menuHeight.current}px`,
        }}
      >
        {items.map((item, index) => (
          <li key={index} className="headerMenuMobile-item">
            {item}
          </li>
        ))}
      </menu>
    </>
  );
};

export default HeaderMenuMobile;
