import Base from "./base";

export default class User extends Base {
  constructor({
    id,
    name = "",
    lastName = "",
    email = "",
    last20quiz = [],
    dateCreated = new Date(),
    dateUpdated = new Date(),
    isAnonymous = false,
  } = {}) {
    super();
    this.id = id;
    this.name = name;
    this.lastName = lastName;
    this.email = email;
    this.last20quiz = last20quiz;
    this.dateCreated = dateCreated;
    this.dateUpdated = dateUpdated;
    this.isAnonymous = isAnonymous;
  }
  static mergeUsers(user, fromUser) {
    const mergedUser = { ...user };
    if (!user.id && fromUser.id) {
      mergedUser.id = fromUser.id;
    }
    if (!user.name && fromUser.name) {
      mergedUser.name = fromUser.name;
    }
    if (!user.lastName && fromUser.lastName) {
      mergedUser.lastName = fromUser.lastName;
    }
    if (!user.email && fromUser.email) {
      mergedUser.email = fromUser.email;
    }
    if (fromUser.hasOwnProperty("isAnonymous")) {
      mergedUser.isAnonymous = !!fromUser.isAnonymous;
    }
    return mergedUser;
  }
  static fromFirestore(data) {
    if (data.dateCreated) {
      data.dateCreated = data.dateCreated.toDate();
    }
    if (data.dateUpdated) {
      data.dateUpdated = data.dateUpdated.toDate();
    }
    return new User(data).getPlainCopy();
  }
  static getUser(user) {
    if (user) {
      return new User({
        ...user,
        id: user.uid,
        name: user.displayName,
      }).getPlainCopy();
    }
    return null;
  }
}
