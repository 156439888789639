import React, { createContext, useEffect, useRef, useState } from "react";
import FadeUpDown from "../components/AnimationComponents/FadeUpDown/FadeUpDown";
import InitialLoading from "../components/LoadingComponents/InitialLoading/InitialLoading";
import Loading from "../components/LoadingComponents/Loading/loading";

export const loadingTypes = {
  initialLoading: "initialLoading",
};

export const LoadingContext = createContext();
const LoadingProvider = ({ children }) => {
  const [loadingStack, setLoadingStack] = useState([
    loadingTypes.initialLoading,
  ]);
  const loadingRender = useRef([]);
  
  const [initLoading, setInitLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setInitLoading(false), 1200);
  });

  const setLoading = (key) => {
    loadingRender.current.push(key);
    setLoadingStack([...loadingRender.current]);
  };
  const removeLoading = (key) => {
    loadingRender.current = loadingRender.current.filter((l) => l !== key);
    setLoadingStack([...loadingRender.current]);
  };
  //TODO: set animation controlled by if it was loaded...
  return (
    <LoadingContext.Provider
      value={{
        loading: loadingStack.length === 0,
        setLoading,
        removeLoading,
        initLoading,
      }}
    >
      <>
        {/*{loadingStack.map((l, i) => (*/}
        {/*  <div key={i}>{l}</div>*/}
        {/*))}*/}
        <InitialLoading in={initLoading} />
        <FadeUpDown show={loadingStack.length > 0} className="cover">
          <Loading />
        </FadeUpDown>
        {children}
      </>
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
