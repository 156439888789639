import { useReducer } from "react";
import QuizTaken from "../dto/quiz/quizTaken";
import produce from "immer";

export const quizTakenActions = {
  saveQuizTaken: "saveQuizTaken",
  questionResponse: "questionResponse",
  questionBooleanResponse: "questionBooleanResponse",
  saveQuestion: "saveQuestion",
  optionResponse: "optionResponse",
  qualifyQuestion: "qualifyQuestion",
};

export const initialState = new QuizTaken().getPlainCopy();

function reducer(state, action) {
  switch (action.type) {
    case quizTakenActions.saveQuizTaken:
      return action.payload;
    case quizTakenActions.saveQuestion: {
      switch (action.payload.questionType) {
        case quizTakenActions.questionResponse:
          state.questions[action.payload.questionIndex].response =
            action.payload.response;
          break;
        case quizTakenActions.questionBooleanResponse:
          state.questions[action.payload.questionIndex].checked =
            action.payload.checked;
          break;
        case quizTakenActions.optionResponse:
          const question = state.questions[action.payload.questionIndex];
          action.payload.options.forEach((option) => {
            const optionIndex = question?.options?.findIndex(
              (o) => o.id === option.id
            );
            question.options[optionIndex].checked = option.checked;
          });
          break;
        default:
          break;
      }
      state.currentQuestion = state.currentQuestion + 1;
      return;
    }
    case quizTakenActions.qualifyQuestion: {

      const question = state.questions.find(q => q.id ===action.payload.id);
      question.questionScore = action.payload.score;
      return;
    }
    default:
      return;
  }
}
const curriedReducerFunction = produce(reducer);

export default function useQuizTakenReducer(quizTaken) {
  const [state, dispatch] = useReducer(
    curriedReducerFunction,
    quizTaken ? quizTaken : initialState
  );

  return {
    quizTaken: state,
    quizDispatch: dispatch,
  };
}
