import produce from "immer";
import { useReducer } from "react";

export const storeActions = {
  addQuiz: "addQuizz",
  removeQuiz: "removeQuiz",
  addGroup: "addGroup",
  removeGroup: "removeGroup"
};

export const initialState = {
  quizzes:{},
  groups:{},  
};

function reducer(state, action) {
  switch (action.type) {
    case storeActions.addQuiz:
      state.quizzes[action.payload.id] = action.payload;
      return;
    case storeActions.removeQuiz:
      delete state.quizzes[action.payload.id];
      return;
    default:
      return state;
  }
}

const curriedReducerFunction = produce(reducer);

export default function useStoreReducer() {
  const [state, dispatch] = useReducer(curriedReducerFunction, initialState);
  return {
    quizzes: state.quizzes,
    storeDispatch: dispatch,
  };
}
