import Base from "../base";
import { questionTypeEnum, studentTakingEnum } from "../enum";
import { nonEmptyObj, objToArray } from "../../utils/generic";
import QuestionOption from "./option";
import Question from "./question";
import { STORAGE } from "../../constants";
import { getQuizTaken, setQuizTaken } from "../../services/quizTakenService";
import { localRemove, localRestore, localStore } from "../../utils/storage";
import { qualifyQuiz } from "../../utils/qualifyQuiz";

//ten seconds
const storageTime = 10000;
export default class QuizTaken extends Base {
  constructor({
    id,
    name = "",
    lastName = "",
    questions = [],
    score = null,
    email = "",
    studentStatus = studentTakingEnum.NEW,
    quizId = "",
    studentId = "",
    live = false,
    total = 0,
    currentQuestion = 0,
    dateStarted = null,
    dateCreated = new Date(),
    dateUpdated = new Date(),
    dateFinished = null,
    relativeTotal = null,
    relativeScore = null,
    duration = null,
  } = {}) {
    super();
    this.id = id;
    this.name = name;
    this.lastName = lastName;
    this.questions = questions;
    this.score = score;
    this.email = email;
    this.studentStatus = studentStatus;
    this.quizId = quizId;
    this.studentId = studentId;
    this.live = live;
    this.total = total;
    this.currentQuestion = currentQuestion;
    this.dateStarted = dateStarted;
    this.dateCreated = dateCreated;
    this.dateUpdated = dateUpdated;
    this.dateFinished = dateFinished;
    this.relativeTotal = relativeTotal;
    this.relativeScore = relativeScore;
    this.dateFinished = dateFinished;
    this.duration = duration;
  }
  static async getSavedQuiz(quizId, userId) {
    if (quizId && userId) {
      let fromStore = this.getFromLocal(quizId, userId);
      if (fromStore) {
        return fromStore;
      }
      return await getQuizTaken(this.createId(quizId, userId));
    }
  }
  static async saveQuiz(quizTaken) {
    if (quizTaken.quizId && quizTaken.studentId) {
      const saveQuizTaken = {
        ...quizTaken,
        id: this.createId(quizTaken.quizId, quizTaken.studentId),
      };
      await setQuizTaken(saveQuizTaken);
      return saveQuizTaken;
    }
  }
  static createId(quizId, userId) {
    return `${quizId ? quizId : ""}-${userId ? userId : ""}`;
  }
  static getStorageKey(quizId, studentId) {
    return `${STORAGE.QUIZ_TAKEN}-${this.createId(quizId, studentId)}`;
  }
  static saveOnLocal(quizTaken) {
    if (quizTaken.studentId && quizTaken.quizId) {
      localStore(this.getStorageKey(quizTaken.quizId, quizTaken.studentId), {
        ...quizTaken,
        dateUpdated: new Date(),
      });
    }
  }
  static deleteFromLocal(quizTaken) {
    if (quizTaken.studentId && quizTaken.quizId) {
      localRemove(this.getStorageKey(quizTaken.quizId, quizTaken.studentId));
    }
  }
  static getFromLocal(quizId, userId, timingRestriction = false) {
    if (!quizId || !userId) {
      return;
    }
    let fromStore = localRestore(this.getStorageKey(quizId, userId));
    if (!fromStore) {
      return;
    }
    fromStore = this.fromLocalStorage(fromStore);
    if (timingRestriction) {
      const time = new Date().getTime() - fromStore.dateUpdated.getTime();
      if (time <= storageTime) {
        return fromStore;
      }
    } else {
      return fromStore;
    }
  }
  static fromLocalStorage(quizTaken) {
    if (quizTaken.dateStarted) {
      quizTaken.dateStarted = new Date(quizTaken.dateStarted);
    }
    if (quizTaken.dateCreated) {
      quizTaken.dateCreated = new Date(quizTaken.dateCreated);
    }
    if (quizTaken.dateUpdated) {
      quizTaken.dateUpdated = new Date(quizTaken.dateUpdated);
    }
    if (quizTaken.dateFinished) {
      quizTaken.dateFinished = new Date(quizTaken.dateFinished);
    }
    return quizTaken;
  }
  static fromFirestore(data) {
    if (data.dateStarted) {
      data.dateStarted = data.dateStarted.toDate();
    }
    if (data.dateCreated) {
      data.dateCreated = data.dateCreated.toDate();
    }
    if (data.dateUpdated) {
      data.dateUpdated = data.dateUpdated.toDate();
    }
    if (data.dateFinished) {
      data.dateFinished = data.dateFinished.toDate();
    }
    if (nonEmptyObj(data.questions)) {
      data.questions = objToArray(data.questions).map((q) => {
        if (q.questionType === questionTypeEnum.MULTICHOICE) {
          if (nonEmptyObj(q.options)) {
            q.options = objToArray(q.options).map((o) =>
              new QuestionOption(o).getPlainCopy()
            );
          }
        }
        return new Question(q).getPlainCopy();
      });
    }
    return new QuizTaken(data).getPlainCopy();
  }
  static userToQuiz(user = {}) {
    const userTaking = {};
    if (user === null) {
      return userTaking;
    }
    if (user.name) {
      userTaking.name = user.name;
    }
    if (user.lastName) {
      userTaking.lastName = user.lastName;
    }
    if (user.email) {
      userTaking.email = user.email;
    }
    if (user.id) {
      userTaking.studentId = user.id;
    }
    return userTaking;
  }
  static quizTakenFromQuiz({ id, ...quiz }, user, fields = {}) {
    const userTaking = this.userToQuiz(user);
    return new QuizTaken({
      ...quiz,
      ...userTaking,
      quizId: id,
      ...fields,
    }).getPlainCopy();
  }
  static isLast(quizTaken) {
    const nextQuestion = quizTaken.currentQuestion + 1;
    return nextQuestion >= quizTaken.questions.length;
  }
  static isFirst(quizTaken) {
    return quizTaken.currentQuestion === 0;
  }
  static getFinishQuiz(quizTaken) {
    const dateFinished = new Date();
    let finishedQT = qualifyQuiz({ ...quizTaken });
    const hasParagraph = quizTaken.questions.some(
      (q) => q.questionType === questionTypeEnum.PARAGRAPH
    );
    finishedQT = {
      ...finishedQT,
      studentStatus: hasParagraph
        ? studentTakingEnum.TO_REVIEW
        : studentTakingEnum.FINISHED,
      dateFinished,
    };
    return finishedQT;
  }
  static reassignQuizToUser(quizTaken, userId) {
    const quizToReturn = {
      ...quizTaken,
      id: this.createId(quizTaken.quizId, userId),
      studentId: userId,
    };
    return quizToReturn;
  }
}
