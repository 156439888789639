export function localRestore(itemKey) {
  let obj = null;

  try {
    const storedData = localStorage.getItem(itemKey);

    if (storedData) {
      obj = JSON.parse(storedData);
    }
  } catch (err) {
    throw new Error("Some json bro the local storage");
  }

  return obj;
}

export function localStore(itemKey, obj) {
  localStorage.setItem(itemKey, JSON.stringify(obj));
}

export function localRemove(itemKey) {
  localStorage.removeItem(itemKey);
}

export function localClear() {
  localStorage.clear();
}
