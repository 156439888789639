//------------ only here we should connect with firebase, or whichever backend we have ------------------

import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  linkWithPopup,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { auth } from "../../config/firebaseConfig";
import { authErrorMessage } from "../errorHandler";
import { getMailCredential, upgradeCredential } from "./authAnonymousService";

auth.useDeviceLanguage();
window.renzoauth = auth;

export const authContextNotifier = (cb) => {
  if (cb) {
    onAuthStateChanged(auth, cb);
  }
};

export const signInWithEmail = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password).then(
    (userCredential) => {
      // Signed in
      const user = userCredential.user;
      return user;
    }
  );
};

export const registerWithEmail = async (
  email,
  password,
  upgradeAnonymous = false
) => {
  if (upgradeAnonymous) {
    const credential = getMailCredential(email, password);
    return await upgradeCredential(credential);
  }
  return await createUserWithEmailAndPassword(auth, email, password);
};

const signInWithProvider = (provider) => {
  return signInWithPopup(auth, provider)
    .then((result) => {
      // console.log('logged', result);
      return result;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.customData.email;
      console.log("error provider", errorCode, errorMessage, email);
      if (errorCode === " auth/popup-closed-by-use") {
        return;
      }
      throw new Error(authErrorMessage(error));
    });
};
const linkWithProvider = (provider) => {
  return linkWithPopup(auth.currentUser, provider).then((result) => {
    // Accounts successfully linked.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const user = result.user;
    return user;
  });
};

export const signInWithGoogle = async (upgradeAnonymous) => {
  const provider = new GoogleAuthProvider();
  if (upgradeAnonymous) {
    return await linkWithProvider(provider);
  }
  return await signInWithProvider(provider);
};

export const signInWithFacebook = async (upgradeAnonymous) => {
  const provider = new FacebookAuthProvider();
  if (upgradeAnonymous) {
    return await linkWithProvider(provider);
  }
  return await signInWithProvider(provider, upgradeAnonymous);
};

export const signOutService = async () => signOut(auth);

export const recoverPasswordService = async (email) => {
  return sendPasswordResetEmail(auth, email);
};

// export const updatePasswordService = async (
//   email,
//   currentPassword,
//   newPassword
// ) => {
//   await auth.signInWithEmailAndPassword(email, currentPassword);
//   await auth.currentUser.updatePassword(newPassword);
// };

// export const updateUserService = async (user) => {
//   await auth.currentUser.updateProfile({
//     displayName: `${user.name} ${user.lastName}`,
//   });
// };
