import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as SiteLogo } from "../../../assets/icons/logo_rect.svg";
import { AuthContext } from "../../../contexts/AuthContext";
import { LoadingContext } from "../../../contexts/LoadingContext";
import { iconType } from "../../BasicComponents/IconButton/IconButton";
import Link from "../../BasicComponents/Link/Link";
import HeaderMenu from "../../HeaderMenu/HeaderMenu";
import "./Header.scss";

const Header = () => {
  const { userLoading, isAuthenticated, signOut, setAuthPopupProp } =
    useContext(AuthContext);
  const { t } = useTranslation();
  const { initLoading } = useContext(LoadingContext);
  const handleSignIn = () => {
    setAuthPopupProp({
      title: t("login.welcome"),
      titleRegister: t("login.sign_up"),
      titlePassword: t("login.forgot_password"),
      show: true,
    });
  };

  const getLinks = () => {
    if (userLoading) {
      return null;
    }
    let links;
    if (isAuthenticated) {
      links = [
        <Link to="/dashboard">{t("navigation.dashboard")}</Link>,
        <Link to="/group">{t("navigation.group")}</Link>,
        <Link to="/settings">{t("navigation.settings")}</Link>,
        <Link onClick={signOut} isButton>
          {t("navigation.sign_out")}
        </Link>,
      ];
    } else {
      links = [
        <Link
          onClick={handleSignIn}
          isButton
          className="loginIcon toogleLink"
          iconType={iconType.loginIcon}
        >
          {t("navigation.sign_in")}
        </Link>,
      ];
    }
    return <HeaderMenu items={links} />;
  };

  return (
    <header className="header">
      <figure
        className={`header-logo ${
          initLoading ? "header-logo-init-animation" : ""
        }`}
        id="header-logo"
      >
        <SiteLogo />
      </figure>
      {getLinks()}
    </header>
  );
};

export default Header;
