import i18next from "i18next";

export const authErrorMessage = (error) => {
  let message = i18next.t("error.auth.generic");
  console.log("this is the error", error, error.code, error.message);

  if (error?.code?.includes("auth/email-already-in-use")) {
    message = i18next.t("error.auth.email_exists");
  }
  if (error?.code?.includes("auth/user-not-found")) {
    message = i18next.t("error.auth.user_not_found");
  }
  if (error?.code?.includes("auth/wrong-password")) {
    message = i18next.t("error.auth.invalid_credentials");
  }
  if (error?.code?.includes("auth/invalid-email")) {
    message = i18next.t("error.auth.invalid_email");
  }
  if (error?.code?.includes("auth/credential-already-in-use")) {
    message = i18next.t("error.auth.credential_registered");
  }
  if (error?.code?.includes("api/quiz-already-taked")) {
    message = i18next.t("error.api.quiz_already_taked");
  }
  return message;
};

export const saveErrorMessage = (error) => {
  let message = i18next.t("error.generic");

  if (error?.code?.includes("permission-denied")) {
    message = i18next.t("error.firestore.permission_denied");
  }
  if (error?.code?.includes("cancelled")) {
    message = i18next.t("error.firestore.cancelled");
  }
  if (error?.code?.includes("invalid_argument")) {
    message = i18next.t("error.firestore.invalid-argument");
  }
  if (error?.code?.includes("not_found")) {
    message = i18next.t("error.firestore.not-found");
  }
  return message;
};
