export const COLLECTIONS = {
  QUIZ: "quizzes",
  USER: "users",
  QUIZ_TAKEN: "quizzes_taken",
  GROUP: "groups",
};

export const STORAGE = {
  QUIZ: "9688955223",
  QUIZ_TAKEN: "2407553557",
  ANONYMOUS: "a203948039",
  SHOW_DASHBOARD: "2023423789",
};
export const NOTIFICATION_TYPE = {
  error: "error",
  warning: "warning",
  success: "success",
};
