/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NewQuizContext } from "../../../contexts/NewQuizContext";
import { UIElementsContext } from "../../../contexts/UIElementsContext";
import { quizActions } from "../../../reducers/quizReducer";
import CheckBox from "../../BasicComponents/CheckBox/CheckBox";
import { InputSize } from "../../BasicComponents/FocusInput/FocusInput";
import IconButton, {
  btnType,
  iconType,
} from "../../BasicComponents/IconButton/IconButton";
import Input from "../../BasicComponents/Input/Input";
import ScrollInToView from "../../hoc/ScrollInToView/ScrollInToView";
import "./OptionEditor.scss";

const OptionEditor = ({ option, optionIndex, questionIndex, focus }) => {
  const { quizDispatch } = useContext(NewQuizContext);
  const { setQuizInput, removeQuizInput } = useContext(UIElementsContext);
  const { t } = useTranslation();
  const inputEl = useRef({});

  useEffect(() => {
    setQuizInput(inputEl, option?.id);
    return () => {
      removeQuizInput(option?.id);
    };
  }, []);
  const saveOptionField = (field, value) => {
    const newOption = {
      ...option,
      [field]: value,
    };
    quizDispatch({
      type: quizActions.modifyOption,
      payload: {
        questionIndex,
        optionIndex,
        option: newOption,
      },
    });
  };
  const deleteOption = () => {
    quizDispatch({
      type: quizActions.deleteOption,
      payload: {
        questionIndex,
        optionIndex,
      },
    });
  };
  return (
    <ScrollInToView
      className="option-editor"
      delay={300}
      offsetViewportBottom={240}
    >
      <CheckBox
        id={option.id + "_checkbox"}
        checked={option?.isCorrect}
        onChange={(e) => {
          saveOptionField("isCorrect", e.target.checked);
        }}
      />
      <Input
        value={option?.option}
        onChange={(e) => {
          saveOptionField("option", e.target.value);
        }}
        size={InputSize.md}
        label={t("quiz_editor.question_type.option_label")}
        noBorder
        focus={focus}
        ref={inputEl}
        id={option?.id}
        contentEditable
      />
      <IconButton
        onClick={deleteOption}
        type={iconType.closeIcon}
        btnType={btnType.light}
        disabled={!optionIndex}
      />
    </ScrollInToView>
  );
};

export default OptionEditor;
