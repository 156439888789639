import ListAnimation from "../../hoc/ListAnimation/ListAnimation";
import QuestionEditor from "../QuestionEditor/QuestionEditor";
import "./QuestionListEditor.scss";

const QuesitonListEditor = ({ questions = [] }) => {
  return (
    <ListAnimation component={"div"}>
      {questions.map((question, index) => (
        <QuestionEditor index={index} key={question.id} question={question} />
      ))}
    </ListAnimation>
  );
};

export default QuesitonListEditor;
