import { useRef, useState } from "react";
import { Transition } from "react-transition-group";
import { getTransformationMetrics } from "../../../utils/domUtils";
import "./InitialLoading.scss";
import { LogoAnimation } from "./LogoAnimation/LogoAnimation";

const animationTime = 500;

const InitialLoading = ({ in: inProp, duration = animationTime }) => {
  const nodeRef = useRef(null);
  const [style, setStyle] = useState({});

  return (
    <Transition
      nodeRef={nodeRef}
      in={inProp}
      timeout={{
        appear: 1000,
        enter: 1000,
        exit: animationTime,
      }}
      unmountOnExit
      onExit={() => {
        const transform = getTransformationMetrics(
          "logo-animation",
          "header-logo"
        );
        if (!transform) {
          setStyle({
            opacity: 0,
          });
          return;
        }
        setStyle({
          transform: `translate(${transform.x}px,${transform.y}px) scale(${transform.scale})`,
        });
      }}
    >
      {(state) => {
        return (
          <div
            className={`initial-loading ${
              state === "exiting" ? "initial-loading-exiting" : ""
            }`}
            ref={nodeRef}
          >
            <LogoAnimation style={style} />
          </div>
        );
      }}
    </Transition>
  );
};

export default InitialLoading;
// scale(${transform.scale})
