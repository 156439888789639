import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import { authErrorMessage } from "../../../services/errorHandler";
import { AuthContext } from "../../../contexts/AuthContext";
import "./LoginForm.scss";
import Input from "../../BasicComponents/Input/Input";
import { InputSize } from "../../BasicComponents/FocusInput/FocusInput";
import ErrorMessage from "../../BasicComponents/ErrorMessage/ErrorMessage";
import ListAnimation from "../../hoc/ListAnimation/ListAnimation";
import { NotificationContext } from "../../../contexts/NotificationContext";
import ButtonLoading, {
  btnStatus,
} from "../../BasicComponents/ButtonLoading/ButtonLoading";

export const formType = {
  login: "login",
  register: "register",
  password: "password",
};

const LoginForm = ({
  defaultEmail = "",
  animationDelay = 0,
  type = formType.login,
  onError,
  onSuccess,
  upgradeAnonymous = false,
  signInMiddleware,
}) => {
  const { t } = useTranslation();
  const { signIn, register, passwordRecovery } = useContext(AuthContext);
  const [hidePassword, setHidePassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
  const { setNotificationSuccess } = useContext(NotificationContext);
  const [loadingStatus, setLoadingStatus] = useState("");
  const mailInput = useRef();
  const passwordInput = useRef();
  useEffect(() => {
    setTimeout(() => {
      if (defaultEmail) {
        passwordInput?.current?.focus();
      } else {
        mailInput?.current?.focus();
      }
    }, animationDelay);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="loginForm">
      <Formik
        initialValues={{
          email: defaultEmail,
          password: "",
          confirmPassword: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t("login.email.valid"))
            .max(255)
            .required(t("login.email.require")),
          password: Yup.string().when([], {
            is: () => type === formType.register || type === formType.login,
            then: Yup.string()
              .max(255)
              .min(6, t("login.password.min"))
              .required(t("login.password.require")),
            otherwise: Yup.string().notRequired(),
          }),
          confirmPassword: Yup.string().when([], {
            is: () => type === formType.register,
            then: Yup.string()
              .required(t("login.password.confirm_require"))
              .oneOf(
                [Yup.ref("password"), null],
                t("login.password.non_equal")
              ),
            otherwise: Yup.string().notRequired(),
          }),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          let response;
          try {
            setLoadingStatus(btnStatus.loading);
            if (type === formType.password) {
              response = await passwordRecovery(values.email);
              setNotificationSuccess(t("login.mail_sent"));
            } else if (type === formType.register) {
              response = await register(
                values.email,
                values.password,
                upgradeAnonymous
              );
            } else {
              if (signInMiddleware) {
                await signInMiddleware(values.email);
              }
              response = await signIn(values.email, values.password);
            }
            setLoadingStatus(btnStatus.success);
            onSuccess && onSuccess(response, type);
          } catch (error) {
            const message = authErrorMessage(error);
            setStatus({ success: false });
            setErrors({ submit: message });
            onError && onError(message);
            setLoadingStatus(btnStatus.fail);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleSubmit,
          isSubmitting,
          values,
          touched,
          handleChange,
          handleBlur,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Input
              value={values.email}
              onChange={handleChange}
              size={InputSize.lg}
              label={t("login.email.label")}
              error={errors.email}
              id="login_id"
              name="email"
              ref={mailInput}
              onBlur={handleBlur}
              touched={touched.email}
            />
            <ListAnimation component="div">
              {[
                ...(type === formType.register || type === formType.login
                  ? [
                      <Input
                        key="password_id"
                        value={values.password}
                        onChange={handleChange}
                        size={InputSize.lg}
                        label={t("login.password.label")}
                        error={errors.password}
                        id="password_id"
                        name="password"
                        ref={passwordInput}
                        onBlur={handleBlur}
                        touched={touched.password}
                        type={hidePassword ? "password" : "text"}
                        passwordIcon
                        iconStatus={hidePassword}
                        iconClicked={() => setHidePassword(!hidePassword)}
                      />,
                    ]
                  : []),
                ...(type === formType.register
                  ? [
                      <Input
                        key="confirmPassword_id"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        size={InputSize.lg}
                        label={t("login.password.confirm")}
                        error={errors.confirmPassword}
                        id="confirmPassword_id"
                        name="confirmPassword"
                        onBlur={handleBlur}
                        touched={touched.confirmPassword}
                        type={hideConfirmPassword ? "password" : "text"}
                        passwordIcon
                        iconStatus={hideConfirmPassword}
                        iconClicked={() =>
                          setHideConfirmPassword(!hideConfirmPassword)
                        }
                      />,
                    ]
                  : []),
              ]}
            </ListAnimation>

            <ErrorMessage
              show={!!errors.submit}
              message={errors.submit}
              size={InputSize.lg}
            />
            <ButtonLoading
              type="submit"
              status={loadingStatus}
              className={`no-shadow ${
                type === formType.register ? "btn-secondary" : ""
              }`}
            >
              {type === formType.register
                ? t("login.sign_up")
                : type === formType.password
                ? t("login.send_recovery_email")
                : t("login.sign_in")}
            </ButtonLoading>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
