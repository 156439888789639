import { useTranslation } from "react-i18next";
import { questionTypeEnum } from "../../../dto/enum";
import Question from "../../../dto/quiz/question";
import Button from "../../BasicComponents/Button/Button";
import "./QuestionTypePicker.scss";

const QuestionTypePicker = ({ questionPicked, currentIndex, children }) => {
  const { t } = useTranslation();

  const addQuestion = (questionType) => {
    return () => {
      questionPicked(
        new Question({
          questionType,
        }).getPlainCopy()
      );
    };
  };

  return (
    <section
      className={`question-type-picker ${!currentIndex ? "no-questions" : ""}`}
    >
      <h3>{t("quiz_editor.question_type.title")}</h3>
      <div className="buttons">
        <Button onClick={addQuestion(questionTypeEnum.PARAGRAPH)}>
          {t("quiz_editor.question_type.short_answer")}
        </Button>
        <Button onClick={addQuestion(questionTypeEnum.MULTICHOICE)}>
          {t("quiz_editor.question_type.options")}
        </Button>
        <Button onClick={addQuestion(questionTypeEnum.BOOLEAN)}>
          {t("quiz_editor.question_type.boolean")}
        </Button>
        {children}
      </div>
    </section>
  );
};

export default QuestionTypePicker;
