import Base from "../base";
import { getTimeId } from "../../utils/generic";

export default class QuestionOption extends Base {
  constructor({
    id = getTimeId(),
    option = "",
    isCorrect = false,
    checked = null,
    validResponse = false,
  } = {}) {
    super();
    this.id = id;
    this.option = option;
    this.isCorrect = isCorrect;
    this.checked = checked;
    this.validResponse = validResponse;
  }
  static isInvalid(QuestionOption) {
    if (!QuestionOption?.option) {
      return QuestionOption.id;
    }
    return false;
  }
}
