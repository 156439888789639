import { createElement, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import "./FadeInOut.scss";

const FadeInOut = ({
  show,
  children,
  className = "",
  el = "div",
  timeout = 300,
  component: Component,
  componentProps = {},
}) => {
  const nodeRef = useRef(null);

  return (
    <CSSTransition
      in={show}
      nodeRef={nodeRef}
      timeout={timeout}
      classNames="fade"
      unmountOnExit
    >
      {() => {
        if (Component) {
          return (
            <Component
              {...{
                ...componentProps,
                className: (componentProps.className || "") + " fade",
              }}
              ref={nodeRef}
            />
          );
        }
        return createElement(
          el,
          { className: "fade " + className, ref: nodeRef },
          children
        );
      }}
    </CSSTransition>
  );
};

export default FadeInOut;
