import { questionStatusEnum, questionTypeEnum } from "../enum";
import Base from "../base";
import { getTimeId } from "../../utils/generic";
import QuestionOption from "./option";
import i18next from "i18next";

export default class Question extends Base {
  constructor({
    id = getTimeId(),
    questionType = "",
    question = "",
    options = [new QuestionOption({ id: getTimeId(1) }).getPlainCopy()],
    response = "",
    isCorrect = false,
    points = null,
    progress = null,
    time = null,
    questionStatus = questionStatusEnum.NOT_VIEWED,
    checked = null,
    required = true,
    validResponse = false,
    questionScore = 0,
  } = {}) {
    super();
    this.id = id;
    this.questionType = questionType;
    this.question = question;
    if (questionType === questionTypeEnum.MULTICHOICE) {
      this.options = options;
    }
    if (questionType === questionTypeEnum.BOOLEAN) {
      this.isCorrect = isCorrect;
    }
    if (questionType === questionTypeEnum.PARAGRAPH) {
      this.response = response;
    }
    this.points = points;
    this.time = time;
    this.progress = progress;
    this.questionStatus = questionStatus;
    this.checked = checked;
    this.required = required;
    this.validResponse = validResponse;
    this.questionScore = questionScore;
  }
  static isInvalid(question) {
    if (question?.question) {
      if (
        question?.questionType === questionTypeEnum.MULTICHOICE &&
        question?.options?.length
      ) {
        const optionId = QuestionOption.isInvalid(question?.options[0]);
        if (optionId) {
          return {
            id: question?.id,
            optionId,
            invalidMessage: i18next.t('quiz_editor.question_type.at_least_one_option')
          };
        }
        return false;
      }
      return false;
    }
    return {
      id: question?.id,
      invalidMessage: i18next.t('quiz_editor.question_type.at_least_one')
    };
  }
}
