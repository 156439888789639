/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import QuizTakenProvider, { QuizTakenContext, } from "../../../contexts/QuizTakenContext";
import { useNavigate, useParams } from "react-router-dom";
import useQuiz from "../../../Hooks/useQuiz";
import { contextKeys, DynamicContext } from "../../../contexts/DynamicContext";
import { LoadingContext } from "../../../contexts/LoadingContext";
import { quizStatusTypeEnum } from "../../../dto/enum";
import QuizFinishedView from "../../../components/QuizFinishedView/QuizFinishedView";
import TakeQuizComponent from "../../../components/TakeQuizComponent/TakeQuizComponent";

const TakeQuizPage = () => {
  const { quizId } = useParams();
  const { setLoading, removeLoading } = useContext(LoadingContext);
  const { addContext } = useContext(DynamicContext);
  const navigate = useNavigate();
  const quiz = useQuiz(quizId);

  useEffect(() => {
    setLoading("PreviewQuizPage");
    addContext(contextKeys.quizTaken, QuizTakenContext);
  }, []);
  useEffect(() => {
    if (quiz === undefined) {
      navigate(`/404`);
      removeLoading("PreviewQuizPage");
      return;
    }
    if (quiz && quiz.id) {
      removeLoading("PreviewQuizPage");
    }
  }, [quiz]);

  if (!quiz) {
    return null;
  }
  if (quiz.quizStatus === quizStatusTypeEnum.FINISHED) {
    return <QuizFinishedView />;
  }
  return (
    <QuizTakenProvider quiz={quiz}>
      <TakeQuizComponent />
    </QuizTakenProvider>
  );
};

export default TakeQuizPage;
