import { createElement, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import "./FadeUpDown.scss";

const FadeUpDown = ({
  show,
  children,
  el = "div",
  timeout = 400,
  className = "",
}) => {
  const nodeRef = useRef(null);
  return (
    <CSSTransition
      in={show}
      nodeRef={nodeRef}
      timeout={timeout}
      classNames={`fadeup`}
      unmountOnExit
    >
      {createElement(
        el,
        { className: "fadeup " + className, ref: nodeRef },
        children
      )}
    </CSSTransition>
  );
};

export default FadeUpDown;
