import { useEffect, useState } from "react";
import { ReactComponent as ButtonLoadingSvg } from "../../../assets/iconAnimations/buttonLoadingIcon.svg";
import "./ButtonLoading.scss";

export const btnStatus = {
  loading: "loading",
  success: "success",
  fail: "fail",
};

const ButtonLoading = ({
  children,
  onClick,
  className = "",
  disabled: propDisabled,
  status: propStatus = "",
  ...props
}) => {
  const [status, setStatus] = useState("");
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setStatus(propStatus);
    if (propStatus === btnStatus.loading) {
      setDisabled(() => true);
    }
    if (propStatus === btnStatus.success || propStatus === btnStatus.fail) {
      setTimeout(() => {
        setStatus("");
        setDisabled(() => false);
      }, 1000);
    }
  }, [propStatus]);

  const handleClick = async () => {
    if (!onClick) {
      return;
    }
    setDisabled(true);
    setStatus(btnStatus.loading);
    let success;
    try {
      success = await onClick(); // return true or false for success or fail animation
    } catch {
      success = false;
    }

    if (success) {
      setStatus(btnStatus.success);
    } else {
      setStatus(btnStatus.fail);
    }
    setTimeout(() => {
      setStatus("");
      setDisabled(false);
    }, 1000);
  };

  return (
    <button
      className={`btn btnLoading ${className} ${status}`}
      onClick={handleClick}
      disabled={disabled || propDisabled}
      {...props}
    >
      <span>{children}</span>
      <ButtonLoadingSvg />
    </button>
  );
};

export default ButtonLoading;
