import { quizStatusTypeEnum, studentTakingEnum } from "../dto/enum";
import { addQuiz } from "../services/quizService";
import { getRelative } from "./generic";

export const getTotal = (quiz) => {
  return quiz.questions.reduce((acc, q) => {
    return acc + (q.points ? +q.points : 1);
  }, 0);
};
export const createQuiz = async (quiz, user) => {
  const saveQuiz = {
    ...quiz,
    professor: {
      id: user.id,
      name: user.name,
      email: user.email,
    },
    quizStatus: quizStatusTypeEnum.CREATED,
    total: getTotal(quiz),
  };
  return await addQuiz(saveQuiz);
};

export const calcGeneralProgress = (quizzesTaken = []) => {
  let questionPosition = 0;
  let questions = 0;
  let score = 0;
  let notDisconnectedTotal = 0;
  let finishedTotal = 0;
  quizzesTaken.forEach((q) => {
    if (q.studentStatus !== studentTakingEnum.DISCONNECTED) {
      notDisconnectedTotal++;
      questionPosition += +q.currentQuestion;
    }
    if (q.studentStatus === studentTakingEnum.FINISHED) {
      finishedTotal++;
      score += +q.score;
    }
    questions = q.questions.length;
  });

  if (notDisconnectedTotal) {
    questionPosition = questionPosition / notDisconnectedTotal;
  }
  if (finishedTotal) {
    score = score / finishedTotal;
  }

  const percentageProgress = getRelative(100, questions, questionPosition);
  return [percentageProgress, score];
};
