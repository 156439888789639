import { useTranslation } from "react-i18next";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { quizTakenActions } from "../../../../reducers/quizTakenReducer";
import useDynamicContext from "../../../../Hooks/useDynamicContext";
import { contextKeys } from "../../../../contexts/DynamicContext";
import CheckBox from "../../../BasicComponents/CheckBox/CheckBox";
import Button from "../../../BasicComponents/Button/Button";

const QuestionBooleanComponent = ({ index, question }) => {
  const { saveDispatch, isLast } = useDynamicContext(contextKeys.quizTaken);
  const { t } = useTranslation();
  const id = "question" + (index + 1);
  const isCorrect = question?.checked;
  const saveQuestion = (val) => {
    const payload = {
      questionType: quizTakenActions.questionBooleanResponse,
      questionIndex: index,
      checked: question.checked,
    };
    if (val === "true") {
      payload.checked = true;
    }
    if (val === "false") {
      payload.checked = false;
    }
    saveDispatch({
      type: quizTakenActions.saveQuestion,
      payload,
    });
  };
  return (
    <div className="questionWrapper">
      <label className="question" htmlFor={id}>
        <span>{index + 1}.</span>
        {question?.question}
      </label>
      <Formik
        initialValues={{
          value: isCorrect,
        }}
        onSubmit={(values) => {
          saveQuestion(values.value);
        }}
      >
        {({ values, handleBlur, setValues }) => (
          <Form>
            <CheckBox
              id={question.id + "_radio"}
              type="radio"
              checked={values.value === "true"}
              name={question.id + "radio"}
              onChange={(e) => {
                setValues({ value: `true` });
              }}
              onBlur={handleBlur}
              label={t('quiz_editor.question_type.true')}
            />
            <CheckBox
              id={question.id + "_radio"}
              type="radio"
              checked={values.value === "false"}
              name={question.id + "radio"}
              value={"false"}
              onChange={(e) => {
                setValues({ value: `false` });
              }}
              onBlur={handleBlur}
              label={t('quiz_editor.question_type.false')}
            />
            <div className="questionParagraphbuttons">
                <Button type="submit" className="no-shadow nextButton">
                  {t(isLast ? "quiz.finish" : "quiz.next")}
                </Button>
              </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default QuestionBooleanComponent;
