import { useTranslation } from "react-i18next";
import Link from "../../BasicComponents/Link/Link";
import "./QuizFinishedComponent.scss";
import { AuthContext } from "../../../contexts/AuthContext";
import { useContext } from "react";
import useDynamicContext from "../../../Hooks/useDynamicContext";
import { contextKeys } from "../../../contexts/DynamicContext";
import Button from "../../BasicComponents/Button/Button";
import { formType } from "../../AuthComponents/loginForm/LoginForm";
import { canUserTakeQuiz } from "../../../services/quizTakenService";
import { providers } from "../../ProvidersLogin/ProvidersLogin";

const QuizFinishedComponent = () => {
  const { quizTaken, reassignQuizToUser } = useDynamicContext(contextKeys.quizTaken);
  const { user, setAuthPopupProp, updateUser } =
    useContext(AuthContext);
  const { t } = useTranslation();

  const handleRegisterAnonytmous = () => {
    setAuthPopupProp({
      title: t("login.welcome"),
      titleRegister: t("login.sign_up"),
      titlePassword: t("login.forgot_password"),
      show: true,
      upgradeAnonymous: true,
      onLogged: async (user, type) => {
        //update auth context
        //verify this....
        if (
          type === formType.register ||
          type === providers.facebook ||
          type === providers.google
        ) {
          updateUser(user);
          return;
        }
        // - change quiz userId or delete
        await reassignQuizToUser(user.uid, quizTaken);
        // update store with returned quiz

      },
      signInMiddleware: async (email) => {
        // validate if user already exists and has already taken the quiz
        return await canUserTakeQuiz(email, quizTaken.quizId);
      },
    });
  };

  return (
    <div className="quizFinishedComponent">
      <h2>{t("quiz.thanks")}</h2>
      <Link to="/">{t("navigation.home")}</Link>
      {/* <p>
        score:{quizTaken.score ? quizTaken.score : 0}/{quizTaken.total}npm
      </p> */}
      {user?.isAnonymous && (
        <Button
          className="no-shadow saveProgress"
          onClick={handleRegisterAnonytmous}
        >
          {t("quiz.save_progress")}
        </Button>
      )}
    </div>
  );
};

export default QuizFinishedComponent;
