import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { COLLECTIONS } from "../constants";
import Quiz from "../dto/quiz/quiz";
import { db } from "../config/firebaseConfig";

export const quizReference = () => {
  return doc(collection(db, COLLECTIONS.QUIZ));
};
export const addQuiz = async (quiz) => {
  // do whatever you have to do with backend
  if (quiz.id) {
    return setQuiz(quiz);
  }
  const quizRef = quizReference();
  const newQuiz = {
    ...quiz,
    id: quizRef.id,
    dateUpdated: new Date(),
    dateCreated: new Date(),
  };
  const quizToFirestore = Quiz.toFirestore(newQuiz);
  return await setDoc(quizRef, quizToFirestore).then(() => newQuiz);
};
export const setQuiz = (quiz) => {
  return new Quiz().getPlainCopy();
  // const quizToFirestore = Quiz.toFirestore({
  //   ...quiz,
  //   dateUpdated: new Date(),
  // });

  // return db
  //   .collection(COLLECTIONS.QUIZ)
  //   .doc(quiz.id)
  //   .set(quizToFirestore)
  //   .then(() => {
  //     return quiz;
  //   });
};
export const getQuiz = (quizId) => {
  const quizRef = doc(db,COLLECTIONS.QUIZ,quizId);
  return getDoc(quizRef).then(doc => {
    if(doc.exists()) {
      return Quiz.fromFirestore(doc.data());
    }
  });
  // return quizRef.get().then((doc) => {
  //   if (doc.exists) {
  //     return Quiz.fromFirestore(doc.data());
  //   }
  // });
};
export const getLiveQuiz = (quizId, callback) => {
  return new Quiz({ id: 2, title: "live quiz" }).getPlainCopy();

  // const quizRef = db.collection(COLLECTIONS.QUIZ).doc(quizId);
  // return quizRef.onSnapshot((doc) => {
  //   if (doc.exists) {
  //     callback(Quiz.fromFirestore(doc.data()));
  //   } else {
  //     callback();
  //   }
  // });
};

export const getAllQuizByUser = (userId) => {
  return Promise.resolve([
    new Quiz({
      id: 1,
      title: "test quiz",
    }),
  ]);

  // const quizRef = db
  //   .collection(COLLECTIONS.QUIZ)
  //   .where("professor.id", "==", `${userId}`);
  // return quizRef.get().then((querySnapshot) => {
  //   const quizzes = [];
  //   querySnapshot.forEach((doc) => {
  //     quizzes.push(Quiz.fromFirestore(doc.data()));
  //   });
  //   return quizzes;
  // })
};

export const configureQuizFn = async (data) => {
  // const headers = await headerWithToken();
  // return fetch(`${host}quiz/configureQuiz`, {
  //   method: "POST", // or 'PUT'
  //   headers,
  //   body: JSON.stringify(data),
  // }).then((response) => response.json());
};
