import { ReactComponent as SiteLogo } from "../../../../assets/icons/logo_rect.svg";
import "./LogoAnimation.scss";

export const LogoAnimation = ({ style = {} }) => {
  return (
    <div id="logo-animation" className="logo-animation" style={style}>
      <SiteLogo />
    </div>
  );
};
