import { Children, createElement, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./ListAnimation.scss";

const ListAnimation = ({
  children,
  component = null,
  className='',
  itemElement = "div",
  ...props
}) => {
  const arrayChildren = Children.toArray(children);

  return (
    <TransitionGroup component={component} {...props} className={`${className} ListAnimation`}>
      {Children.map(arrayChildren, (child, index) => (
        <CSSTransition
          key={child.props.id}
          timeout={300}
          classNames="item"
          onEnter={(el) => {
            el.style.height = `${el.scrollHeight}px`;
          }}
          onEntered={(el) => {
            el.style.height = `auto`;
          }}
          onExit={(el) => {
            el.style.height = `${el.scrollHeight}px`;
          }}
          onExiting={(el) => {
            el.style.height = `0px`;
          }}
        >
          {createElement(itemElement, { className: "item"},child)}
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export default ListAnimation;
