/* eslint-disable no-useless-computed-key */
import React, { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CreateQuizPage from "./pages/quiz/createQuizPage/createQuizPage";
import MainLayout from "./components/layouts/MainLayout/MainLayout";
import MainGuard from "./components/guards/MainGuard";
import LoggedGuard from "./components/guards/LoggedGuard";
import LoadingFallback from "./components/LoadingComponents/LoadingFallback/LoadingFallback";
import { pageAnimations } from "./components/layouts/PageTransition/PageTransition";
import TakeQuizPage from "./pages/quiz/takeQuiz/takeQuizPage";

//lazy components
const Error404Page = lazy(() => import("./pages/Error404Page"));
const LoginPage = lazy(() => import("./pages/auth/login/loginPage"));
const RegisterPage = lazy(() =>
  import("./pages/auth/registerPage/RegisterPage")
);
const ForgotPasswordPage = lazy(() =>
  import("./pages/auth/forgotPassword/ForgotPasswordPage")
);
const DashboardPage = lazy(() => import("./pages/dashboard/dashboardPage"));
const DuplicateQuiz = lazy(() =>
  import("./pages/quiz/duplicateQuiz/duplicateQuiz")
);
const ConfigureQuizPage = lazy(() =>
  import("./pages/quiz/configureQuizPage/configureQuizPage")
);
const ViewQuiz = lazy(() => import("./pages/quiz/viewQuiz/ViewQuiz"));
const PreviewQuizPage = lazy(() =>
  import("./pages/previewQuiz/PreviewQuizPage")
);
// const TakeQuizPage = lazy(() => import("./pages/quiz/takeQuiz/takeQuizPage"));
const DashboardQuizPage = lazy(() =>
  import("./pages/dashboard/dashboardQuiz/dashboardQuizPage")
);
const GroupsPage = lazy(() => import("./pages/GroupsPage/GroupsPage"));
const GroupEditComponent = lazy(() =>
  import("./components/Groups/GroupEditComponent/GroupEditComponent")
);
const SettingsPage = lazy(() => import("./pages/settingsPage/SettingsPage"));

const router = createBrowserRouter([
  {
    errorElement: <Error404Page />,
    children: [
      {
        path: "/",
        element: (
          <MainLayout>
            <CreateQuizPage />
          </MainLayout>
        ),
        loader() {
          return {
            // ["/auth/login"]: pageAnimations.leftToRight,
            // ["/auth/register"]: pageAnimations.leftToRight,
            // ["/auth/forgotPassword"]: pageAnimations.leftToRight,
          };
        },
      },
      {
        element: <LoggedGuard />,
        children: [
          {
            path: "dashboard",
            element: (
              <MainLayout>
                <DashboardPage />
              </MainLayout>
            ),
          },
          {
            path: "new-quiz",
            element: (
              <MainLayout>
                <CreateQuizPage />
              </MainLayout>
            ),
          },
          {
            path: "new-quiz/:quizId",
            element: (
              <MainLayout>
                <DuplicateQuiz />
              </MainLayout>
            ),
          },
          {
            path: "quiz/configure/:quizId",
            element: (
              <MainLayout>
                <ConfigureQuizPage />
              </MainLayout>
            ),
          },
          {
            exact: true,
            path: "/view-quiz/:quizId",
            element: (
              <MainLayout>
                <ViewQuiz />
              </MainLayout>
            ),
          },
          {
            path: "/dashboard/:quizId",
            component: (
              <MainLayout>
                <DashboardQuizPage />
              </MainLayout>
            ),
          },
          {
            path: "/group",
            element: (
              <MainLayout>
                <GroupsPage />
              </MainLayout>
            ),
            children: [
              {
                path: ":groupId",
                element: <GroupEditComponent />,
              },
            ],
          },
          {
            path: "/settings",
            element: (
              <MainLayout>
                <SettingsPage />
              </MainLayout>
            ),
          },
        ],
      },
      {
        path: "/preview/:quizId",
        element: <PreviewQuizPage />,
      },
      {
        path: "/:quizId",
        element: <TakeQuizPage />,
      },
    ],
  },
]);

const RoutesComponent = () => {
  return (
    <Suspense fallback={<LoadingFallback />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};
export default RoutesComponent;
