import { useTranslation } from "react-i18next";
import "./PoweredBy.scss";
import { getDomain } from "../../utils/generic";

const PoweredBy = () => {
  const { t } = useTranslation();
  const domain = getDomain();
  return (
    <a href={domain} className="poweredBy" target="_blank" rel="noreferrer">
      {t("poweredby.label")}
    </a>
  );
};

export default PoweredBy;
