import { signInAnonymousUser } from "../../services/authService/authAnonymousService";


export function useAnonymousFunctions(user, setUser = () => {}) {
  const signInAnonymous = async (anonymous) => {
    return await signInAnonymousUser(anonymous);
  };
  // const upgradeAnonymous = (upgradedUser) => {
  //   let fromUser = getUser(upgradedUser);
  //   const updatedUser = User.mergeUsers(user, fromUser);
  //   setUser(updatedUser);
  // };
  // const linkAnonymousWithFacebook = async () => {
  //   const { user } = await linkWithFacebook();
  //   upgradeAnonymous(user);
  // };
  // const linkAnonymousWithGoogle = async () => {
  //   const { user } = await linkWithGoogle();
  //   upgradeAnonymous(user);
  // };
  // const linkAnonymousWithMail = async (email, password) => {
  //   await linkWithMail(email, password);
  //   upgradeAnonymous({ email });
  // };

  return {
    signInAnonymous,
    // linkAnonymousWithFacebook,
    // linkAnonymousWithGoogle,
    // linkAnonymousWithMail,
  };
}
