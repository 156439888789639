const events = {};
let eventId = 0;
window.renzoRegister = events;


const addRegister = (cb, id, type) => {
  if (!type) {
    return;
  }
  if (!events[type]) {
    events[type] = {};
  }
  if (!events[type][id]) {
    events[type][id] = cb;
  }
};

const removeRegister = (id, type) => {
  if (events[type] && events[type][id]) {
    delete events[type][id];
  }
};

export const triggerEvt = (type, ...data) => {
  if (!type) return;
  if (!events[type]) return;
  for (let [, value] of Object.entries(events[type])) {
    value(...data);
  }
};

export const unsubscribeEvt = (id, type) => {
  if (Array.isArray(type)) {
    type.forEach((t) => removeRegister(id, t));
  } else {
    removeRegister(id, type);
  }
};

export const subscribeEvt = (cb, type, id = cb.name) => {
  if (!type) return;
  if (!id) {
    id = "event" + eventId;
    eventId++;
  }
  if (Array.isArray(type)) {
    type.forEach((t) => addRegister(cb, id, t));
  } else {
    addRegister(cb, id, type);
  }
  return id;
};