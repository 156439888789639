import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../config/firebaseConfig";
import Quiz from "../dto/quiz/quiz";
import QuizTaken from "../dto/quiz/quizTaken";
import { COLLECTIONS } from "../constants";

// export const quizTakenReference = () => {
//   return db.collection(COLLECTIONS.QUIZ_TAKEN).doc();
// };
// export const addQuizTaken = async (quizTaken) => {
//   if (quizTaken.id) {
//     return setQuizTaken(quizTaken);
//   }
//   const quizTakenRef = quizTakenReference(quizTaken);
//   const newQuizTaken = { ...quizTaken, id: quizTakenRef.id };
//   const quizToFirestore = QuizTaken.toFirestore(newQuizTaken);
//   return quizTakenRef.set(quizToFirestore).then(() => {
//     return newQuizTaken;
//   });
// };
export const setQuizTaken = (quizTaken) => {
  const quizToFirestore = QuizTaken.toFirestore({
    ...quizTaken,
    dateUpdated: new Date(),
  });
  const quizTakenRef = doc(db, COLLECTIONS.QUIZ_TAKEN, quizTaken.id);
  return setDoc(quizTakenRef, quizToFirestore).then(() => quizTaken);
};
export const getQuizTaken = (quizTakenId) => {
  const quizTakenRef = doc(db, COLLECTIONS.QUIZ_TAKEN, quizTakenId);
  return getDoc(quizTakenRef).then((doc) => {
    if (doc.exists()) {
      return QuizTaken.fromFirestore(doc.data());
    }
  });
};
export const getLiveQuizTaken = (quizTakenId, callback) => {
  return new QuizTaken().getPlainCopy();
  // const quizTakenRef = db.collection(COLLECTIONS.QUIZ_TAKEN).doc(quizTakenId);
  // return quizTakenRef.onSnapshot((doc) => {
  //   callback(QuizTaken.fromFirestore(doc.data()));
  // });
};
export const getAllLiveQuizTakenByQuiz = (quizId, callback) => {
  return [new Quiz().getPlainCopy()];
  // const quizTakenRef = db
  //   .collection(COLLECTIONS.QUIZ_TAKEN)
  //   .where("quizId", "==", `${quizId}`);
  // return quizTakenRef.onSnapshot((querySnapshot) => {
  //   const quizzes = [];
  //   querySnapshot.forEach((doc) => {
  //     quizzes.push(QuizTaken.fromFirestore(doc.data()));
  //   });
  //   callback(quizzes);
  // });
};

export const canUserTakeQuiz = async (email, quizId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API}/app/quizTaken/canUserTakeQuiz?email=${email}&quizId=${quizId}`,
    {
      method: "GET",
    }
  );
  const jsonData = await response.json();
  if(jsonData?.code === 'api/quiz-already-taked') {
    throw jsonData;
  }
};

export const reassignQuiz = async (userId, quizTakenId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API}/app/quizTaken/reassignQuiz?userId=${userId}&quizTakenId=${quizTakenId}`,
    {
      method: "PUT",
    }
  );
  const jsonData = await response.json();
  return jsonData;
}