import React from "react";
import Header from "../Header/Header";
import PageTransition from "../PageTransition/PageTransition";
import "./MainLayout.scss";

const MainLayout = ({ children }) => {
  // const { t } = useTranslation();
  // const { isAuthenticated } = useContext(AuthContext);
  return (
    <div className="container">
      <Header />
      <main className="main">
        <PageTransition>
          {children}
          {/* <footer className="footer">
          {isAuthenticated && <Link to="/new-quiz">{t("navigation.new")}</Link>}
        </footer> */}
        </PageTransition>
      </main>
    </div>
  );
};

export default MainLayout;
