import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    errorMessage: "",
  };
  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      errorMessage: error || this.props.t("error.generic"),
    });
  }

  render() {
    if (this.state.hasError) {
      return <h2>{this.state.errorMessage}</h2>;
    }
    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
