import {
  EmailAuthProvider,
  linkWithCredential,
  signInAnonymously,
} from "firebase/auth";
import { auth } from "../../config/firebaseConfig";
import { STORAGE } from "../../constants";
import { localStore } from "../../utils/storage";

export const signInAnonymousUser = async (anonymous) => {
  localStore(STORAGE.ANONYMOUS, anonymous);
  return await signInAnonymously(auth);
};

export const getMailCredential = (mail, password) => {
  return EmailAuthProvider.credential(mail, password);
};
export const upgradeCredential = async (credential) => {
  return linkWithCredential(auth.currentUser, credential)
    .then((usercred) => {
      const user = usercred.user;
      console.log("Anonymous account successfully upgraded", user);
      return user;
    });
};

// export const linkWithPopup = async (provider) => {
//   return auth.currentUser.linkWithPopup(provider);
// };

// export const linkWithGoogle = () => {
//   const provider = new GoogleAuthProvider();
//   return linkWithPopup(provider);
// };

// export const linkWithFacebook = () => {
//   const provider = new FacebookAuthProvider();
//   return linkWithPopup(provider);
// };

// export const linkWithMail = (email, password) => {
//   const credential = makeEmailCredential(email, password);
//   return auth.currentUser.linkWithCredential(credential);
// };

// export const getAnonymousUser = (user) => {
//   if (user.isAnonymous) {
//     const anonymousLS = localRestore(STORAGE.ANONYMOUS);
//     if (anonymousLS && isObj(anonymousLS)) {
//       return { ...user, ...anonymousLS };
//     }
//   }
//   return user;
// };
