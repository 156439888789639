import { useEffect } from "react";
import { NOTIFICATION_TYPE } from "../../constants";
import IconButton, {
  btnType,
  iconType,
} from "../BasicComponents/IconButton/IconButton";
import "./Notification.scss";

const Notification = ({
  id,
  message = "",
  type = NOTIFICATION_TYPE.success,
  time = 10,
  close,
  closeButton = true
}) => {
  useEffect(() => {
    let timer;
    if (time && !isNaN(time)) {
      timer = setTimeout(() => {
        close(id);
      }, time * 1000);
    }
    return () => {
      timer && clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      className={`notification ${type} ${!!close ? "notification-close" : ""}`}
    >
      <h3 className="notification-title">{message}</h3>
      {close && closeButton && (
        <IconButton
          onClick={() => close(id)}
          type={iconType.closeIcon}
          btnType={btnType.light}
          className="notification-close-button"
        />
      )}
    </div>
  );
};

export default Notification;
