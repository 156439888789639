import {
  recoverPasswordService,
  registerWithEmail,
  signInWithEmail,
  signInWithFacebook,
  signInWithGoogle,
  signOutService,
} from "../../services/authService/authService";

export function useAuthFunctions() {
  const signIn = async (email, password) => {
    // call to whatever service
    return await signInWithEmail(email, password);
  };
  const signInGoogle = async (upgradeAnonymous) => {
    return await signInWithGoogle(upgradeAnonymous);    
  };
  const signInFacebook = async (upgradeAnonymous) => {
    return await signInWithFacebook(upgradeAnonymous);
  };
  const signOut = async () => {
    await signOutService();
  };
  const register = async (email, password,upgradeAnonymous = false) => {
    return await registerWithEmail(email, password,upgradeAnonymous);
  };

  const passwordRecovery = async (email) => {
    await recoverPasswordService(email);
  }
  // const updatePassword = async (email, currentPassword, newPassword) => {
  //   await updatePasswordService(email, currentPassword, newPassword);
  // }

  // const updateUser = async (user) => {
  //   await updateUserService(user);
  //   await setUser(user);
  // }

  return {
    signInGoogle,
    signInFacebook,
    signOut,
    signIn,
    register,
    passwordRecovery,
    // updatePassword,
    // updateUser
  };
}
