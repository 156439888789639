/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect } from "react";
import useQuizReducer, {
  initialState,
  quizActions,
} from "../reducers/quizReducer";
import { STORAGE } from "../constants";
import { localRemove, localRestore, localStore } from "../utils/storage";
import Quiz from "../dto/quiz/quiz";
import { useHasSignedEvt } from "../Hooks/auth/useHasSignedEvt";

export const NewQuizContext = createContext(initialState);

export default function NewQuizProvider({ children, quizDB }) {
  const { quiz, quizDispatch } = useQuizReducer(quizDB);

  useHasSignedEvt({
    signOut: () => {
      localRemove(STORAGE.QUIZ);
      quizDispatch({
        type: quizActions.deleteQuiz,
      });
    },
  });

  useEffect(() => {
    const storedQuiz = localRestore(STORAGE.QUIZ);
    if (storedQuiz) {
      quizDispatch({
        type: quizActions.saveQuiz,
        payload: Quiz.fromLocalStorage(storedQuiz),
      });
    }
  }, []);

  useEffect(() => {
    localStore(STORAGE.QUIZ, quiz);
  }, [quiz]);

  return (
    <NewQuizContext.Provider
      value={{
        quiz,
        quizDispatch,
      }}
    >
      {children}
    </NewQuizContext.Provider>
  );
}
