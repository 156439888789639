import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import { getId } from "../../../utils/generic";
import EyeAnimation from "../../AnimationComponents/animations/EyeAnimation/EyeAnimation";
import ContentEditableClass from "../ContentEditableClass";
import "./FocusInput.scss";
import { sizes } from "../../ResizeComponent/ResizeComponent";

export const InputSize = {
  lg: "input-lg",
  md: "",
};
export const InputClass = {
  label: "input-label",
  layer: "input-layer",
};

const FocusInput = forwardRef(
  (
    {
      value,
      onChange,
      id = getId(),
      label = "",
      size = InputSize.md,
      inputClass = InputClass.label,
      focus = false,
      contentEditable = false,
      noBorder = false,
      onBlur,
      type = "text",
      passwordIcon = false,
      iconStatus = true,
      iconClicked = () => {},
      ...props
    },
    ref
  ) => {
    const input = useRef();
    const { t } = useTranslation();

    useEffect(() => {
      if (focus && input?.current?.focus && sizes.minSm()) {
        input.current.focus();
      }
    }, [focus]);

    useImperativeHandle(
      ref,
      () => ({
        focus() {
          input.current.scrollIntoView({ behavior: "smooth", block: "center" });
          if (sizes.minSm()) {
            input.current.focus({ preventScroll: true });
          }
        },
      }),
      []
    );
    return (
      <span
        className={`focusInput ${
          !!value ? "filled" : ""
        } ${size} ${inputClass} ${passwordIcon ? "input-with-icon" : ""}`}
      >
        {!contentEditable ? (
          <input
            className="field"
            id={id}
            ref={input}
            type={type}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            {...props}
          />
        ) : (
          <ContentEditableClass
            innerRef={input}
            className="field"
            html={value}
            onChange={onChange}
            onBlur={onBlur}
            id={id}
            {...props}
          />
        )}
        {passwordIcon && (
          <button
            onClick={iconClicked}
            className="input-icon"
            title={t("login.password.show_hide")}
            type="button"
          >
            <EyeAnimation closed={iconStatus} />
          </button>
        )}
        <label htmlFor={id}>
          <span>{label}</span>
        </label>
        {!noBorder && <span className="border" />}
      </span>
    );
  }
);

export default FocusInput;
