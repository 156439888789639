import { useContext, useEffect, useRef } from "react";
import { AuthContext } from "../../contexts/AuthContext";

export function useHasSignedEvt({
  signIn = () => {},
  signOut = () => {},
  signInAnonymous = () => {},
  anonymousUpgraded = () => {},
} = {},dependencies = []) {
  const { prevUser, user, isAuthenticated } = useContext(AuthContext);
  const firstRender = useRef(true);

  useEffect(() => {
    if(user === prevUser) {
      return;
    }
    if (
      (user && user.id && !prevUser) ||
      (isAuthenticated && firstRender.current)
    ) {
      signIn(user);
      if (user.isAnonymous) {
        signInAnonymous(user);
      }
    }
    if (prevUser && prevUser.id && !user) {
      signOut();
    }
    if (prevUser && prevUser.isAnonymous && user && !user.isAnonymous) {
      anonymousUpgraded(user);
    }
    firstRender.current = false;
    // eslint-disable-next-line
  }, [user, ...dependencies]);
}
