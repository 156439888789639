import { Timestamp } from "firebase/firestore";
import {
  arrayOfStrings,
  arrayToObj,
  emptyArray,
  emtpyObj,
  isDate,
  nonEmptyArray,
  nonEmptyObj,
} from "../utils/generic";
export default class Base {
  getPlainCopy() {
    return { ...this };
  }
  static getFirebaseDate(value) {
    return Timestamp.fromDate(new Date());;
  }

  static toFirestore(quiz) {
    const obj = {};
    for (const [key, value] of Object.entries(quiz)) {
      if (emptyArray(value) || emtpyObj(value)) {
        continue;
      }
      if (nonEmptyArray(value)) {
        if (arrayOfStrings(value)) {
          obj[key] = value;
        } else {
          let objFromArray = arrayToObj(value);
          objFromArray = this.toFirestore(objFromArray);
          obj[key] = objFromArray;
        }
      } else if (nonEmptyObj(value)) {
        obj[key] = this.toFirestore({ ...value });
      } else if (isDate(value)) {
        obj[key] = this.getFirebaseDate(value);
      } else if (value) {
        obj[key] = value;
      }
    }
    return obj;
  }
}
