/* eslint-disable */
import React, {
  createContext,
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAnonymousFunctions } from "../Hooks/auth/useAnonymousFunctions";
import { useAuthFunctions } from "../Hooks/auth/useAuthFunctions";
import { usePrevious } from "../Hooks/usePrevious";
import ModalAnimation from "../components/AnimationComponents/ModalAnimation/ModalAnimation";
import AuthPopup from "../components/AuthComponents/AuthPopup/AuthPopup";
import User from "../dto/user";
import { LoadingContext } from "./LoadingContext";
import { authContextNotifier } from "../services/authService/authService";

const initialAuthPopupProp = {
  show: false,
  title: "",
  defaultEmail: "",
  hideProviders: false,
  pendingCred: null,
};
const initialState = {
  user: undefined,
};

export const AuthContext = createContext(initialState);
const AuthProvider = memo(({ children }) => {
  const { removeLoading } = useContext(LoadingContext);
  const [user, setUser] = useState("");
  const prevUser = usePrevious(user);
  const [authPopupProp, setAuthPopupProp] = useState(initialAuthPopupProp);
  const closeAuthPopup = () => setAuthPopupProp(initialAuthPopupProp);
  // if you have to complete some proccess in a page after login you can use this pending page
  const pendingPage = useRef("");
  const setPendingPage = (v) => (pendingPage.current = v);
  window.renzoUser = user;
  window.renzoprevUser = user;
  const {
    signInGoogle,
    signInFacebook,
    signOut,
    signIn,
    register,
    passwordRecovery,
    //   updatePassword,
    //   updateUser
  } = useAuthFunctions(closeAuthPopup);
  const {
    signInAnonymous,
    // linkAnonymousWithFacebook,
    // linkAnonymousWithGoogle,
    // linkAnonymousWithMail,
  } = useAnonymousFunctions(user, setUser);

  const clearUsers = () => {
    setUser(null);
    // localRemove(STORAGE.ANONYMOUS);
  };
  const updateUser = (user) => {
    let authUser = User.getUser(user);
    setUser(authUser);
  };

  useEffect(() => {
    authContextNotifier((userUpdated) => {
      console.log("onauthstatechanged", userUpdated);
      updateUser(userUpdated);
      if (userUpdated) {
        closeAuthPopup();
      } else {
        clearUsers();
      }
      removeLoading("AuthProvider");
    });
  }, []);
  const isAuthenticated = !!user && !user.isAnonymous;

  return (
    <>
      <AuthContext.Provider
        value={{
          isAuthenticated,
          userLoading: user === "",
          user,
          updateUser,
          prevUser,
          setAuthPopupProp,
          signInGoogle,
          signInFacebook,
          signOut,
          signIn,
          register,
          signInAnonymous,
          // linkAnonymousWithFacebook,
          // linkAnonymousWithGoogle,
          // linkAnonymousWithMail,
          passwordRecovery,
          // updatePassword,
          // updateUser
          pendingPage,
          setPendingPage,
          closeAuthPopup,
        }}
      >
        {children}
        <ModalAnimation
          in={authPopupProp.show && !isAuthenticated}
          close={closeAuthPopup}
          closeButton
          delayExit
        >
          <AuthPopup authPopupProp={authPopupProp} close={closeAuthPopup} />
        </ModalAnimation>
      </AuthContext.Provider>
    </>
  );
});
export default AuthProvider;
