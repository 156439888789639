import { useRef, useState } from "react";
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";
import IconButton, {
  btnType,
  iconType,
} from "../../BasicComponents/IconButton/IconButton";
import "./ModalAnimation.scss";

const modalAnimation = {
  revealing: "revealing",
};
const modalElement = document.getElementById("modal-root");
const rootElement = document.getElementById("root");
const ModalAnimation = ({
  in: inProp,
  children,
  timeout = 500,
  close,
  closeButton = false,
  delayExit = false,
  layerExit = false,
}) => {
  const nodeRef = useRef(null);
  const [delayClass, setDelayClass] = useState(true);

  const layerClick = (e) => {
    if (!close || !layerExit) return;
    if (e.target.classList.contains("modal-layer")) {
      handleClose();
    }
  };
  const onEnter = () => {
    rootElement.classList.add("modal-displayed");
  };
  const onExit = () => {
    rootElement.classList.remove("modal-displayed");
  };
  const handleClose = () => {
    if (delayExit) {
      setDelayClass(false);
    }
    close();
  };
  const getDelayClass = () => {
    if (delayExit && !inProp && delayClass) {
      return " delayExit";
    }
    return "";
  };

  return createPortal(
    <CSSTransition
      in={inProp}
      nodeRef={nodeRef}
      timeout={delayExit ? 1000 : timeout}
      classNames={modalAnimation.revealing}
      unmountOnExit
      onEnter={onEnter}
      onExit={onExit}
    >
      <div
        className={`modal modal-layer ${
          modalAnimation.revealing
        } ${getDelayClass()}`}
        ref={nodeRef}
        onClick={layerClick}
      >
        <div className="modal-content">
          <div
            className={`modal-content-inner ${
              !!closeButton ? "modal-content-close" : ""
            }`}
          >
            {closeButton && (
              <IconButton
                onClick={handleClose}
                type={iconType.closeIcon}
                btnType={btnType.light}
                className="modal-close"
              />
            )}
            {children}
          </div>
        </div>
      </div>
    </CSSTransition>,
    modalElement
  );
};

export default ModalAnimation;
