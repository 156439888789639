export const quizStatusTypeEnum = {
  DRAFT: 'DRAFT',
  CREATED: 'CREATED',
  STARTED: 'STARTED',
  TO_QUALIFY: 'TO_QUALIFY',
  FINISHED: 'FINISHED'
};

export const questionTypeEnum = {
  MULTICHOICE: 'MULTICHOICE',
  BOOLEAN: 'BOOLEAN',
  PARAGRAPH: 'PARAGRAPH'
};

export const questionStatusEnum = {
  NOT_VIEWED: 'NOT_VIEWED',
  VIEWED: 'VIEWED',
  SKIPPED: 'SKIPPED',
  CORRECT: 'CORRECT',
  WRONG: 'WRONG',
};

export const studentTakingEnum = {
  NEW:"NEW",
  SUBSCRIBED: "SUBSCRIBED",
  STARTED: "STARTED",
  DISCONNECTED: "DISCONNECTED",
  TO_REVIEW: "TO_REVIEW",
  FINISHED: "FINISHED"
};
