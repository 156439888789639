import { useTranslation } from "react-i18next";
import useDynamicContext from "../../../Hooks/useDynamicContext";
import { contextKeys } from "../../../contexts/DynamicContext";
import ButtonLoading from "../../BasicComponents/ButtonLoading/ButtonLoading";
import "./StartQuiz.scss";
import { useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Button from "../../BasicComponents/Button/Button";

const StartQuiz = () => {
  const { startQuiz, setStartQuizOnLogin } = useDynamicContext(contextKeys.quizTaken);
  const { isAuthenticated, setAuthPopupProp, signInAnonymous } =
    useContext(AuthContext);
  const { t } = useTranslation();
  const showLogin = () => {
    setStartQuizOnLogin();
    setAuthPopupProp({
      show: true,
      title: t("quiz.register.enter_with_user")
    });
  };

  const handleStartQuiz = async () => {
    if (!isAuthenticated) {
      try {
        await signInAnonymous();
        return true;
      } catch (e) {
        return false;
      }
    }
    return await startQuiz();
  };

  return (
    <div className="startQuiz">
      <ButtonLoading
        className="btn-light-border btn-lg startBtn"
        onClick={handleStartQuiz}
      >
        {t("quiz.start")}
      </ButtonLoading>

      {!isAuthenticated && (
        <>
          <p>{t("quiz.register.or")}</p>
          <Button className="btn-lg no-shadow registerBtn" onClick={showLogin}>
            {t("quiz.register.enter_with_user")}
          </Button>
        </>
      )}
    </div>
  );
};

export default StartQuiz;
