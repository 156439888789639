import QuestionCheckboxComponent from "./QuestionCheckboxComponent/QuestionCheckboxComponent";
import QuestionRatioComponent from "./QuestionRatioComponent/QuestionRatioComponent";

const QuestionMultichoiceComponent = ({ index, question }) => {
  const hasOnlyOne = question?.options?.filter((o) => o.isCorrect).length === 1 && question?.options?.length > 1;
  return (
    <>
      {hasOnlyOne ? (
        <QuestionRatioComponent
          index={index}
          question={question}
        />
      ) : (
        <QuestionCheckboxComponent
          index={index}
          question={question}
        />
      )}
    </>
  );
};

export default QuestionMultichoiceComponent;
