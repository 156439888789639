export function isElementInViewport(el, bottomOffset = 0) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom + bottomOffset <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
export function getTransformationMetrics(fromId, toId) {
  // debugger;
  if (!fromId || !toId) {
    return;
  }
  const fromElem = document.getElementById(fromId);
  const toElem = document.getElementById(toId);
  if (!fromElem || !toElem) {
    return;
  }
  const fromMetrics = fromElem.getBoundingClientRect();
  const toMetrics = toElem.getBoundingClientRect();

  return {
    x: -(fromMetrics.x - toMetrics.x),
    y: -(fromMetrics.y - toMetrics.y),
    scale: toMetrics.width / fromMetrics.width,
  };
}
export function isHidden(el) {
  const style = window.getComputedStyle(el);
  return (style.display === 'none')
}