import { ReactComponent as DeleteIcon } from "../../../assets/icons/deleteIcon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/closeIcon.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plusIcon.svg";
import { ReactComponent as SaveIcon } from "../../../assets/icons/saveIcon.svg";
import { ReactComponent as LoginIcon } from "../../../assets/icons/loginIcon.svg";
import { ReactComponent as CreateQuizIcon } from "../../../assets/icons/createQuizIcon.svg";
import { ReactComponent as LinkIcon } from "../../../assets/icons/linkIcon.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/icons/facebookIcon.svg";
import { ReactComponent as GoogleIcon } from "../../../assets/icons/googleIcon.svg";
import "./IconButton.scss";
import { forwardRef } from "react";
export const btnType = {
  light: "btn-light",
};
export const iconType = {
  deleteIcon: "deleteIcon",
  closeIcon: "closeIcon",
  plusIcon: "plusIcon",
  saveIcon: "saveIcon",
  loginIcon: "loginIcon",
  createQuizIcon: "createQuizIcon",
  linkIcon: "linkIcon",
  facebookIcon: "facebookIcon",
  googleIcon: "googleIcon"
};
export const iconSize = {
  sm: "btn-sm",
  md: "btn-md",
};
const IconButton = forwardRef(
  (
    {
      onClick,
      type,
      size = iconSize.md,
      className = "",
      btnType = "",
      disabled = false,
      label,
      elementType = "btn",
    },
    ref
  ) => {
    let icon = null;
    if (type === iconType.deleteIcon) {
      icon = <DeleteIcon className="stroke" />;
    } else if (type === iconType.closeIcon) {
      icon = <CloseIcon className="stroke fill" />;
    } else if (type === iconType.plusIcon) {
      icon = <PlusIcon className="stroke" />;
    } else if (type === iconType.saveIcon) {
      icon = <SaveIcon className="stroke" />;
    } else if (type === iconType.loginIcon) {
      icon = <LoginIcon className="stroke fill" />;
    } else if (type === iconType.createQuizIcon) {
      icon = <CreateQuizIcon className="stroke" />;
    } else if (type === iconType.linkIcon) {
      icon = <LinkIcon className="stroke" />;
    } else if (type === iconType.facebookIcon) {
      icon = <FacebookIcon className="fill" />;
    } else if (type === iconType.googleIcon) {
      icon = <GoogleIcon className="fill" />;
    }
    if (elementType === "btn") {
      return (
        <button
          onClick={onClick}
          className={`btn ${btnType} icon-btn ${
            size === iconSize.sm ? "btn-sm" : ""
          } ${className} ${!!label ? "label" : ""}`}
          disabled={disabled}
          ref={ref}
        >
          {icon}
          {label && <span>{label}</span>}
        </button>
      );
    } else {
      return (
        <i
          ref={ref}
          className={`btn ${btnType} icon-btn ${
            size === iconSize.sm ? "btn-sm" : ""
          } ${className}`}
        >
          {icon}
        </i>
      );
    }
  }
);

export default IconButton;
