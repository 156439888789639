import React from 'react';
import './loading.scss';
import { ReactComponent as LoadingAnimation } from '../../../assets/icons/loadingAnimation.svg';

const Loading = () => {
  return (
    <div className="loading-screen">
      <LoadingAnimation />
    </div>
  );
};

export default Loading;