import { createContext, useRef } from "react";
//use to have dinamic support context for components used on different contexts

export const contextKeys = {
  saveQuiz: "saveQuiz",
  quizTaken: "quizTaken",
};
export const  DynamicContext = createContext();
const DynamicProvider = ({ children }) => {
  const { current: contexts } = useRef({});

  const addContext = (key, ref) => {
    contexts[key] = ref;
  };
  const removeContext = (key) => {
    delete contexts[key];
  };

  return (
    <DynamicContext.Provider
      value={{
        contexts,
        addContext,
        removeContext,
      }}
    >
      {children}
    </DynamicContext.Provider>
  );
};

export default DynamicProvider;
