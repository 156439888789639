import { createContext, memo, useEffect, useState } from "react";
import { NOTIFICATION_TYPE } from "../constants";
import Notification from "../components/Notification/Notification";
import { getId } from "../utils/generic";
import ListAnimation from "../components/hoc/ListAnimation/ListAnimation";
import { createPortal } from "react-dom";

const notificationElement = document.getElementById("notification-root");
export const NotificationContext = createContext();
const NotificationProvider = memo(({ children }) => {
  const [messages, setMessages] = useState([]);

  const addNotification = (notification) => {
    setMessages((mlist) => {
      return [...mlist, notification];
    });
  };

  const setError = (message, time) => {
    const errorMessage = {
      id: getId(),
      type: NOTIFICATION_TYPE.error,
      message,
      time,
    };
    addNotification(errorMessage);
  };
  const setSuccess = (message, time, closeButton = true) => {
    const successMessage = {
      id: getId(),
      type: NOTIFICATION_TYPE.success,
      message,
      time,
      closeButton,
    };
    addNotification(successMessage);
  };
  const close = (id) => {
    setMessages((mlist) => {
      const list = [...mlist];
      const index = list.findIndex((e) => e.id === id);
      list.splice(index, 1);
      return list;
    });
  };

  return (
    <>
      {createPortal(
        <div className="notification-wrapper">
          <ListAnimation
            component="ul"
            className="ul-notification"
            itemElement="li"
          >
            {messages.map((message) => (
              <Notification key={message.id} {...message} close={close} />
            ))}
          </ListAnimation>
        </div>,
        notificationElement
      )}
      <NotificationContext.Provider
        value={{
          setNotificationError: setError,
          setNotificationSuccess: setSuccess,
        }}
      >
        {children}
      </NotificationContext.Provider>
    </>
  );
});

export default NotificationProvider;
