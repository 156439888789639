/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { STORAGE } from "../../../constants";
import { AuthContext } from "../../../contexts/AuthContext";
import { LoadingContext } from "../../../contexts/LoadingContext";
import { NewQuizContext } from "../../../contexts/NewQuizContext";
import { NotificationContext } from "../../../contexts/NotificationContext";
import { StoreContext } from "../../../contexts/StoreContext";
import { UIElementsContext } from "../../../contexts/UIElementsContext";
import Quiz from "../../../dto/quiz/quiz";
import User from "../../../dto/user";
import { quizActions } from "../../../reducers/quizReducer";
import { storeActions } from "../../../reducers/storeReducer";
import { saveErrorMessage } from "../../../services/errorHandler";
import { createQuiz } from "../../../utils/quizGeneric";
import { localRemove } from "../../../utils/storage";
import FadeInOut from "../../AnimationComponents/FadeInOut/FadeInOut";
import IconButton, {
  iconType,
} from "../../BasicComponents/IconButton/IconButton";
import QuesitonListEditor from "../QuestionListEditor/QuesitonListEditor";
import QuestionTypePicker from "../QuestionTypePicker/QuestionTypePicker";
import "./QuizEditor.scss";
import { useHasSignedEvt } from "../../../Hooks/auth/useHasSignedEvt";

const QuizEditor = () => {
  const { t } = useTranslation();
  const { quiz, quizDispatch } = useContext(NewQuizContext);
  const { storeDispatch } = useContext(StoreContext);
  const { getQuizInput } = useContext(UIElementsContext);
  const { user, setAuthPopupProp, pendingPage, setPendingPage } =
    useContext(AuthContext);
  const { setLoading, removeLoading } = useContext(LoadingContext);
  const { setNotificationError, setNotificationSuccess } =
    useContext(NotificationContext);

  const navigate = useNavigate();

  useHasSignedEvt({
    signIn: () => {
      if (pendingPage.current) {
        save(User.getUser(user));
        setPendingPage("");
      }
    },
  });

  const addQuestion = (question) => {
    quizDispatch({
      type: quizActions.addQuestion,
      payload: question,
    });
  };

  const save = async (userEvt) => {
    setLoading("QuizEditor");
    try {
      const savedQuiz = await createQuiz(quiz, userEvt);
      quizDispatch({
        type: quizActions.saveQuiz,
        payload: savedQuiz,
      });
      storeDispatch({
        type: storeActions.addQuiz,
        payload: savedQuiz,
      });
      setNotificationSuccess(t("create_quiz_page.success"));
      localRemove(STORAGE.QUIZ);
      navigate(`/quiz/configure/${savedQuiz.id}`);
      removeLoading("QuizEditor");
    } catch (e) {
      console.log("error", e);
      setNotificationError(saveErrorMessage(e));
      removeLoading("QuizEditor");
    }
  };

  const onSave = () => {
    //validate if ate least one question
    const invalidFieldObj = Quiz.isInvalid(quiz);
    if (invalidFieldObj) {
      const input = getQuizInput(invalidFieldObj);
      input?.focus();
      input?.setError(invalidFieldObj.invalidMessage);
      return;
    }
    // clean quiz remove empty questions and options
    const cleanQuiz = Quiz.cleanquiz(quiz);
    quizDispatch({
      type: quizActions.setQuestions,
      payload: cleanQuiz.questions,
    });
    //TODO: an anonimous user might pass this validation
    if (!user) {
      setPendingPage("/");
      setAuthPopupProp({
        show: true,
        title: t("login.title.save_quiz", { login: t("login.title.login") }),
        titleRegister: t("login.title.save_quiz", {
          login: t("login.title.register"),
        }),
        titlePassword: t("login.title.recover_password"),
      });
      // this is used to prevent guard from leaving this page on login
    } else {
      save(user);
    }
  };

  return (
    <div className="quiz-editor">
      <QuesitonListEditor questions={quiz?.questions} />
      <QuestionTypePicker
        questionPicked={addQuestion}
        currentIndex={quiz?.questions?.length}
      >
        <FadeInOut
          show={!!quiz?.questions?.length}
          component={IconButton}
          componentProps={{
            className: "btn-secondary btn-save",
            onClick: onSave,
            label: t("create_quiz_page.finish"),
            type: iconType.saveIcon,
          }}
        />
      </QuestionTypePicker>
    </div>
  );
};

export default QuizEditor;
