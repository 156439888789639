import useDynamicContext from "../../Hooks/useDynamicContext";
import { contextKeys } from "../../contexts/DynamicContext";
import { studentTakingEnum } from "../../dto/enum";
import FadeInOut from "../AnimationComponents/FadeInOut/FadeInOut";
import SwitchAnimation, {
  switchAnimations,
} from "../AnimationComponents/SwitchAnimation/SwitchAnimation";
import Loading from "../LoadingComponents/Loading/loading";
import Progress from "../Progress/Progress";
import Timer from "../TImer/Timer";
import QuestionComponent from "./QuestionComponent/QuestionComponent";
import "./QuizComponent.scss";
import QuizFinishedComponent from "./QuizFinished/QuizFinishedComponent";
import StartQuiz from "./StartQuiz/StartQuiz";

const QuizComponent = () => {
  const { quizTaken, finishQuiz, loading } = useDynamicContext(
    contextKeys.quizTaken
  );
  if (!quizTaken) {
    return null;
  }

  const getQuestion = () => {
    if (loading) {
      return <Loading id="loading" />;
    }
    if (quizTaken.studentStatus === studentTakingEnum.NEW) {
      return <StartQuiz id="StartQuiz" />;
    } else if (
      quizTaken.studentStatus === studentTakingEnum.FINISHED ||
      quizTaken.studentStatus === studentTakingEnum.TO_REVIEW
    ) {
      return <QuizFinishedComponent id="QuizFinishedComponent" />;
    }

    const question = quizTaken?.questions[quizTaken.currentQuestion];
    return (
      <QuestionComponent
        index={quizTaken.currentQuestion}
        id={quizTaken.currentQuestion}
        question={question}
      />
    );
  };
  return (
    <div className="quizComponent">
      <FadeInOut
        className="quizComponent-header"
        show={
          quizTaken.studentStatus === studentTakingEnum.STARTED ||
          quizTaken.studentStatus === studentTakingEnum.FINISHED
        }
      >
        <Progress
          value={quizTaken.currentQuestion}
          max={quizTaken?.questions.length}
        />
        {/* {quizTaken.duration !== 0 && (
          <Timer duration={quizTaken.duration} onFinish={finishQuiz} />
        )} */}
      </FadeInOut>
      <SwitchAnimation className={switchAnimations.slideTransition}>
        {getQuestion()}
      </SwitchAnimation>
    </div>
  );
};

export default QuizComponent;
