import "./App.scss";
import "./config/firebaseConfig";
import AuthProvider from "./contexts/AuthContext";
import DynamicProvider from "./contexts/DynamicContext";
import LoadingProvider from "./contexts/LoadingContext";
import NotificationProvider from "./contexts/NotificationContext";
import StoreProvider from "./contexts/StoreContext";
import UIElementsProvider from "./contexts/UIElementsContext";
import RoutesComponent from "./RoutesComponent";
import "./services/multiLanguage";

function App() {
  return (
    <UIElementsProvider>
      <LoadingProvider>
        <NotificationProvider>
          <AuthProvider>
            <StoreProvider>
              <DynamicProvider>
                <RoutesComponent />
              </DynamicProvider>
            </StoreProvider>
          </AuthProvider>
        </NotificationProvider>
      </LoadingProvider>
    </UIElementsProvider>
  );
}

export default App;
