import { ReactComponent as LoginIcon } from "../../../../assets/iconAnimations/loginIcon.svg";
import "./LoginAnimation.scss";

export const loginIconType = {
  loginIconUser: "loginIconUser",
  loginIconCheck: "loginIconCheck",
  loginIconRegister: "loginIconRegister",
  loginIconLock: "loginIconLock",
  loginIconRecover: "loginIconRecover",
};

const LoginAnimation = ({ status }) => {
  return <LoginIcon className={`loginIcon ${status}`} />;;
};

export default LoginAnimation;
