import "./TakeQuizComponent.scss";
import PoweredBy from "../PoweredBy/PoweredBy";
import QuizComponent from "../QuizComponent/QuizComponent";

const TakeQuizComponent = () => {
  

  return (
    <div className="takeQuiz">
      <QuizComponent />
      <div className="poweredWrapper">
        <PoweredBy />
      </div>
    </div>
  );
};

export default TakeQuizComponent;
