import { useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Navigate, Outlet } from "react-router-dom";

const LoggedGuard = ({ children }) => {
  const { user } = useContext(AuthContext);
  const page = useMemo(() => {
    if (user === null) {
      return <Navigate to="/" />;
    }
    return <Outlet />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return page;
};

export default LoggedGuard;
