import { questionTypeEnum } from "../dto/enum";
import { getRelativeScore } from "./generic";

export function qualifyBooleanQuestion(question) {
  question.points = question.points ? question.points : 1;
  if (question.checked === null) {
    return question;
  }
  question.validResponse = !!question.checked === !!question.isCorrect;
  if (question.validResponse) {
    question.questionScore = question.points;
  } else {
    question.questionScore = 0;
  }
  return question;
}
export function qualifyMultichoiceQuestion(question) {
  let questionScore = 0;
  let totalCorrectOptions = 0;
  question.points = question.points ? question.points : 1;
  question.options = question.options.map((o) => {
    if (!o.isCorrect) {
      return o;
    }
    const newOption = { ...o };
    totalCorrectOptions++;
    newOption.validResponse = !!o.checked;
    if (newOption.validResponse) {
      questionScore++;
    }
    return newOption;
  });
  if (totalCorrectOptions === 0) {
    question.questionScore = question.points;
    return question;
  }

  question.questionScore = getRelativeScore(
    question.points,
    totalCorrectOptions,
    questionScore
  );
  return question;
}
export function qualifyQuiz(quizTaken, automated = true) {
  let score = 0;
  let totalPoints = 0;
  quizTaken.questions = quizTaken.questions.map((q) => {
    let newQuestion = { ...q };
    if (automated && q.questionType === questionTypeEnum.BOOLEAN) {
      newQuestion = qualifyBooleanQuestion(newQuestion);
    }
    if (automated && q.questionType === questionTypeEnum.MULTICHOICE) {
      newQuestion = qualifyMultichoiceQuestion(newQuestion);
    }
    score = score + +newQuestion.questionScore;
    totalPoints = totalPoints + (newQuestion.points ? +newQuestion.points : 1);
    return newQuestion;
  });
  quizTaken.score = score;
  quizTaken.total = totalPoints;
  return quizTaken;
}
