import React from "react";
import NewQuizProvider from "../../../contexts/NewQuizContext";
import ErrorBoundary from "../../../components/layouts/ErrorHandler/ErrorBoundary";
import QuizEditor from "../../../components/QuizEditor/QuizEditor/QuizEditor";

const CreateQuizPage = () => {
  return (
    <NewQuizProvider>
      <ErrorBoundary>
        <QuizEditor />
      </ErrorBoundary>
    </NewQuizProvider>
  );
};

export default CreateQuizPage;
