import {useEffect, useState} from "react";
import {getLiveQuiz, getQuiz} from "../services/quizService";

export default function useQuiz(quizId, live = false) {
  const [quiz, setQuiz] = useState(null);

  useEffect(() => {
    if (live) {
      getLiveQuiz(quizId, setQuiz);
      return;
    }
    getAsyncQuiz();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAsyncQuiz = async () => {
    const dbQuiz = await getQuiz(quizId);
    setQuiz(dbQuiz);
  };
  return quiz;
}
