import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LoginAnimation, {
  loginIconType,
} from "../../AnimationComponents/animations/LoginAnimation/LoginAnimation";
import Link from "../../BasicComponents/Link/Link";
import LoginForm, { formType } from "../loginForm/LoginForm";
import ProvidersLogin from "../../ProvidersLogin/ProvidersLogin";
import "./AuthPopup.scss";
import ListAnimation from "../../hoc/ListAnimation/ListAnimation";

const AuthPopup = ({
  authPopupProp,
  close,
  linkButton = true,
  authType = formType.login,
}) => {
  const [type, setType] = useState(() => authType);
  const { t } = useTranslation();
  const handleProviderSigned = (user,type) => {
    setIconStatus(loginIconType.loginIconCheck);
    if (authPopupProp.onLogged) {
      setTimeout(() => {
        authPopupProp.onLogged(user,type);
      }, 1500);
    }
  };

  const [iconStatus, setIconStatus] = useState(() => {
    if (authType === formType.register) {
      return loginIconType.loginIconRegister;
    }
    if (authType === formType.password) {
      return loginIconType.loginIconRecover;
    }
    return loginIconType.loginIconUser;
  });

  let title = authPopupProp.title;
  if (type === formType.password && authPopupProp.titlePassword) {
    title = authPopupProp.titlePassword;
  } else if (type === formType.register && authPopupProp.titleRegister) {
    title = authPopupProp.titleRegister;
  }
  return (
    <div
      className={`authPopup ${
        type === formType.register ? "authPopup-register" : ""
      }`}
    >
      {authPopupProp.title && <h3 className="authPopup-title">{title}</h3>}
      <figure>
        <LoginAnimation status={iconStatus} />
      </figure>
      {!authPopupProp.hideProviders && (
        <ListAnimation component={"div"}>
          {type !== formType.password
            ? [
                <div key="providers" className="providers">
                  <ProvidersLogin
                    signed={handleProviderSigned}
                    upgradeAnonymous={authPopupProp.upgradeAnonymous}
                  />
                  <p>{t("login.or")}</p>
                </div>,
              ]
            : []}
        </ListAnimation>
      )}
      <LoginForm
        {...authPopupProp}
        animationDelay={400}
        type={type}
        onError={() => {
          setIconStatus(loginIconType.loginIconLock);
        }}
        onSuccess={(data,type) => {
          setIconStatus(loginIconType.loginIconCheck);
          if (type === formType.password) {
            setTimeout(() => {
              close && close();
            }, 400);
          }
          if (authPopupProp.onLogged) {
            setTimeout(() => {
              authPopupProp.onLogged(data, type);
            }, 400);
          }
        }}
      />
      <div className="viewButtons">
        {type !== formType.login && (
          <Link
            onClick={() => {
              setType(() => formType.login);
              setIconStatus(loginIconType.loginIconUser);
            }}
            to="/auth/login"
            isButton={linkButton}
          >
            {t("login.sign_in")}
          </Link>
        )}
        {type !== formType.register && (
          <Link
            isButton={linkButton}
            to="/auth/register"
            onClick={() => {
              setType(() => formType.register);
              setIconStatus(loginIconType.loginIconRegister);
            }}
          >
            {t("login.sign_up")}
          </Link>
        )}
        {type !== formType.password && (
          <Link
            to="/auth/forgotPassword"
            onClick={() => {
              setType(() => formType.password);
              setIconStatus(loginIconType.loginIconRecover);
            }}
            isButton={linkButton}
          >
            {t("login.forgot_password")}
          </Link>
        )}
      </div>
    </div>
  );
};

export default AuthPopup;
