import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import configFile from "./configFile.json";

const app = initializeApp(configFile);
export const db = getFirestore(app);
export const auth = getAuth(app);

if (process.env.NODE_ENV === "development") {
  // to work without cloud functions 
  // connectFirestoreEmulator(db, "localhost", 8080);
  // connectAuthEmulator(auth, "http://localhost:9099",{ disableWarnings: true });

  // to work with cloud functions
  connectFirestoreEmulator(db, "localhost", 6060);
  connectAuthEmulator(auth, "http://localhost:6070",{ disableWarnings: true });
}
