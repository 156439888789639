let lastId = 0;
export const getId = (prefix = "id") => {
  lastId++;
  return `${prefix}${lastId}`;
};
export const getTimeId = (minus = 0) => {
  return new Date().getTime() - minus;
};
export const isDate = (value) => {
  return (
    typeof value === "object" &&
    value !== null &&
    typeof value.getMonth === "function"
  );
};
export const isObj = (value) => {
  return value && value.constructor === Object;
};
export const emtpyObj = (value) => {
  return isObj(value) && !Object.keys(value).length;
};
export const nonEmptyObj = (value) => {
  return isObj(value) && Object.keys(value).length;
};
export const nonEmptyArray = (value) => {
  return Array.isArray(value) && value.length;
};
export const emptyArray = (value) => {
  return Array.isArray(value) && !value.length;
};
export const arrayOfStrings = (value) => {
  return value.every((i) => typeof i === "string");
};
export const arrayToObj = (value) => {
  return Object.assign({}, { ...value });
};
export const objToArray = (value) => {
  return Object.values(value);
};

export const getNumber = (value, defaultValue = 0) => {
  const n = Number(value);
  return isNaN(n) ? defaultValue : n;
};

export const getRelative = (totalRelative, totalScore, score) => {
  if (totalScore === 0) {
    return 0;
  }
  const relativeScore = (totalRelative * score) / totalScore;
  return oneDecimal(relativeScore);
};

export const removeFromArray = (array,value) => {
  let tmpArray = [...array];
  const index = tmpArray.indexOf(value);
  if(index > -1) {
    tmpArray.splice(index, 1);
  }
  return tmpArray;
};

export const getRelativeScore = (totalRelative, totalScore, score) => {
  let totalRelativeV = getNumber(totalRelative, 1);
  let totalScoreV = getNumber(totalScore, 1);
  let scoreV = getNumber(score, 1);
  return getRelative(totalRelativeV, totalScoreV, scoreV);
};

export const oneDecimal = (n) => parseFloat(n.toFixed(1));

export const getDomain = () => {
  return window.location.protocol + "//" + window.location.host;
};

export const secsToHHMMSS = (secs, view = false) => {
  const date = new Date(secs * 1000);
  const dateRef = date.toISOString().substr(11, 8);
  if (view) {
    return dateRef;
  }
  return dateRef.split(":").map((e) => +e);
};

export const hhmmssToSecs = (h, m, s) => +h * 60 * 60 + +m * 60 + +s;
export const cloneDate = (date) => new Date(date.getTime());
