// import { questionTypeEnum, quizStatusTypeEnum } from "../enum";
import { questionTypeEnum, quizStatusTypeEnum } from "../enum";
import Base from "../base";
import Question from "./question";
import { nonEmptyObj, objToArray } from "../../utils/generic";
import QuestionOption from "./option";
// import { nonEmptyObj, objToArray } from "../../utils/generic";
// import Question from "./question";
// import QuestionOption from "./option";

export default class Quiz extends Base {
  constructor({
    id,
    title = "",
    quizStatus = quizStatusTypeEnum.DRAFT,
    dateCreated = new Date(),
    questions = [],
    classId = "",
    registrationLink = "",
    professor = {},
    time = null,
    dateStarted = null,
    generalProgress = null,
    generalScore = null,
    className = "",
    total = null,
    relativeTotal = null,
    students = [],
    dateUpdated = null,
    dateFinalized = null,
    duration = 0,
  } = {}) {
    super();
    this.id = id;
    this.title = title;
    this.quizStatus = quizStatus;
    this.dateCreated = dateCreated;
    this.questions = questions;
    this.classId = classId;
    this.registrationLink = registrationLink;
    this.professor = professor;
    this.time = time;
    this.dateStarted = dateStarted;
    this.generalProgress = generalProgress;
    this.generalScore = generalScore;
    this.className = className;
    this.total = total;
    this.relativeTotal = relativeTotal;
    this.students = students;
    this.dateUpdated = dateUpdated;
    this.dateFinalized = dateFinalized;
    this.duration = duration;
  }

  static fromFirestore(data) {
    if (data.dateCreated) {
      data.dateCreated = data.dateCreated.toDate();
    }
    if (data.dateUpdated) {
      data.dateUpdated = data.dateUpdated.toDate();
    }
    if (data.dateFinalized) {
      data.dateFinalized = data.dateFinalized.toDate();
    }
    if (nonEmptyObj(data.questions)) {
      data.questions = objToArray(data.questions).map((q) => {
        if(q.questionType === questionTypeEnum.MULTICHOICE) {
          if(nonEmptyObj(q.options)) {
            q.options = objToArray(q.options).map(o => new QuestionOption(o).getPlainCopy());
          }
        }
        return new Question(q).getPlainCopy();
      });
    }
    if (nonEmptyObj(data.students)) {
      data.students = objToArray(data.students);
    }
    return new Quiz(data).getPlainCopy();
  }
  static fromLocalStorage(quiz) {
    if (quiz.dateCreated) {
      quiz.dateCreated = new Date(quiz.dateCreated);
    }
    if (quiz.dateUpdated) {
      quiz.dateUpdated = new Date(quiz.dateUpdated);
    }
    if (quiz.dateFinalized) {
      quiz.dateFinalized = new Date(quiz.dateFinalized);
    }

    return quiz;
  }
  static isInvalid(quiz) {
    if (quiz?.questions?.length > 0) {
      let invalidQuestionId = 0;
      // searach if at least one is valid
      for (let question of quiz.questions) {
        let invalid = Question.isInvalid(question);
        // false is not invalide at least one question is not empty.
        if (!invalid) {
          return false;
        }
        // {id:'...',optionId:'...' } if the question has an option id, means is a checkbox question and had no options filled.
        if (invalid?.optionId) {
          return invalid;
        }
      }
      // validate the first question
      return Question.isInvalid(quiz?.questions[invalidQuestionId]);
    }
    return false;
  }
  static cleanquiz(q) {
    const quiz = { ...q };
    quiz.questions = quiz.questions.reduce((acc, q) => {
      const question = { ...q };
      if (question.questionType === questionTypeEnum.MULTICHOICE) {
        question.options = question.options.reduce((oAcc, opt) => {
          const option = { ...opt };
          if (option.option) {
            oAcc.push(option);
          }
          return oAcc;
        }, []);
        if (question.options.length || question.question) {
          acc.push(question);
        }
      } else if (question.question) {
        acc.push(question);
      }
      return acc;
    }, []);
    return quiz;
  }
}
