import { useEffect, useState } from "react";
import { onSizes, sizes } from "../ResizeComponent/ResizeComponent";
import "./HeaderMenu.scss";
import HeaderMenuMobile from "./HeaderMenuMobile/HeaderMenuMobile";

const HeaderMenu = ({ items }) => {
  const [displayMobile, setDisplayMobile] = useState(() => sizes.maxXs());
  useEffect(() => {
    const maxSubs = onSizes.subsMaxXs(() => {
      setDisplayMobile(true);
    });
    const minSubs = onSizes.subsMinSm(() => {
      setDisplayMobile(false);
    });
    return () => {
      onSizes.unsubsMaxXs(maxSubs);
      onSizes.unsubsMinSm(minSubs);
    };
  }, []);

  if (displayMobile && items.length > 1) {
    return <HeaderMenuMobile items={items} />;
  }
  return (
    <menu className="headerMenu">
      {items.map((item, index) => (
        <li key={index} className="headerMenu-item">
          {item}
        </li>
      ))}
    </menu>
  );
};

export default HeaderMenu;
