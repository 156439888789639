import { createRef } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import './SwitchAnimation.scss';
import { usePrevious } from "../../../Hooks/usePrevious";

const switchRefs = {};
export const switchAnimations = {
  leftToRight: "leftToRight",
  rightToLeft: "rightToLeft",
  fadeTransition: "fadeTransition",
  slideTransition: "slideTransition"
};
const SwitchAnimation = ({ children, className : classNameProp = switchAnimations.fadeTransition }) => {

  const prevId = usePrevious(children.props.id);
  

  if (!switchRefs[children.props.id]) {
    switchRefs[children.props.id] = createRef();
  }
  let className = classNameProp;
  if(className === switchAnimations.slideTransition) {
    if(prevId > children.props.id) {
      className = switchAnimations.leftToRight;
    } else {
      className = switchAnimations.rightToLeft;
    }
  }

  return (
    <TransitionGroup className={`switchTransition ${className}`}>
      {[
        <CSSTransition
          key={children.props.id}
          timeout={600}
          classNames="item"
          unmountOnExit
          appear
        >
          {() => (
            <div ref={switchRefs[children.props.id]} className={`item`}>
              {children}
            </div>
          )}
        </CSSTransition>,
      ]}
    </TransitionGroup>
  );
};

export default SwitchAnimation;
